/* #Paragraph
   -------------------------------------------------------------------------- */
/*doc
---
title: paragraph
name: paragraph
categories: [component]
---
文章の段落のコンポーネントです。
```block
<p class="c-paragraph"></p>
```
*/
.c-paragraph {
	margin-bottom: 1.5rem;
}

.c-paragraph--none {
	margin-bottom: 0;
}