/* #Main
   -------------------------------------------------------------------------- */
/**
 * Mainレイヤーにはプロジェクトにおける、基本的なベーススタイルを定義します。
 * 要素セレクタや属性セレクタなど、詳細度はできるかぎり低く保っておきます。
 * 基本的にclass属性は使用しません。
 * Bootstrapのオーバーライドスタイルを定義します。
 */
/**
 */
h1, h2, h3, h4, h5, h6 {
	font-family: inherit;
	line-height: 1.4;
	color: inherit;
}

h1 {}
h2 {}
h3 {}
h4 {}
h5 {}
h6 {}

ul, ol {
	padding-left: 0;
	list-style: none;
}

/**
 * ネストされたリストのマージンをリセットします。
 */
li {
	> ul, > ol {
		margin-bottom: 0;
	}
}

/**
 * `dt`と`dd`の左端を揃えます。
 */
dd {
	margin-left: 0;
	margin-bottom: 0;
}

table {
	width: 100%;
}

th {
	text-align: left;
	padding: 1.5rem;
	font-weight: bold;
}

td {
	padding: 1.5rem;
}

a {
	text-decoration: underline;
	color: #333;
	&:visited {
		text-decoration: underline;
		color: #333;
	}
	&:hover {
		text-decoration: none;
	}
}

a, input, textarea, select, button, {
	&:focus {
		outline: 4px solid $_color-important01 !important;
		outline-offset: 0px;
	}
}

div:focus-visible{
	outline: 4px solid $_color-important01 !important;
	outline-offset: 0px;	
}

/**
 * 日本語では斜体を使用しないためリセットします。
 */
i,
cite,
em,
address,
dfn {
	font-style: normal;
}

pre{
	padding: 2em;
	margin: 1em 0;
}

code,
pre {
	font-family: Consolas, Menlo, Courier, monospace;
}

code {
	padding: 0.2em;
	background-color: rgba(0,0,0,0.06);
}

pre code {
	padding: 0;
	background-color: inherit;
}

h1, h2, h3, h4, h5, h6,
ul, ol, dl,
blockquote, p, address,
hr,
table,
fieldset, figure,
pre {
	margin-top: 0;
	margin-bottom: 0;
}

em {
	font-style: normal;
	font-weight: bold;
}

strong {
	color: #2E6D9D;
}

/**
 * Bootstrapのオーバーライド
 */
 
.img-fluid img {
	 max-width: 100%;
	 height: auto;
 }
 
.col-15, .col-sm-15, .col-md-15, .col-lg-15 {
	position: relative;
	min-height: 1px;
	padding-right: 15px;
	padding-left: 15px;
	width: 100%;
}
.red{
  background-color: red;
}
.blue{
  background-color: blue;
}
@media (min-width: 768px) {
  .col-sm-15 {
    width: 20%;
    flex: 0 0 20%;
  }
}
@media (min-width: 992px) {
  .col-md-15 {
    width: 20%;
    flex: 0 0 20%;
  }
}
@media (min-width: 1200px) {
  .col-lg-15 {
    width: 20%;
    flex: 0 0 20%;
  }
}
.col-15{
  width: 20%;
  flex: 0 0 20%;
}

/**
 * NetCommons3 標準対策
 */
 
#nc-system-footer {
	display: none;
}