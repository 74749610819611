.sitemap-body{
	
	a{
		color: #333 !important;
	}
	
	& h2{
		margin-bottom: 1.6rem;
		margin-top: 2.4rem;
		padding-left: 1.2rem;
		font-size: 1.8rem;
		position: relative;
		font-weight: normal;
		
		&::before{
			position: absolute;
			content: "";
			left: 0;
			bottom: 0;
			width: 0.4rem;
			height: 100%;
			border-radius: 1.6rem;
			background: $_color04;
			
		}	
		
		& > h3{
			padding-top: 0;
		}
	}
	
	
	& h3{
		font-size: 1.6rem;
		padding-bottom: 1.6rem;
		padding-top: 1.6rem;
		border-bottom: 0.2rem dashed $_color07;
		padding-left: 1.6rem;
		font-weight: normal;
		position: relative;
		
		&::before {
			display: inline-block;
			position: absolute;
			top: 50%;
			left: 0;
			width: .6rem;
			height: 1.1rem;
			margin-top: -.4rem;
			background: url(../images/ico_arrow03.svg);
			background-repeat: repeat;
			background-size: auto;
			background-size: contain;
			background-repeat: no-repeat;
			content: "";
		}
		
	}
	
	& ul{
		display: flex;
		& li{
			padding-top: 0.8rem;
			padding-bottom: 0.8rem;
			padding-left: 3.2rem;
			
			& a{
				padding-left: 1rem;
				position: relative;
				&::before {
					display: inline-block;
					position: absolute;
					top: 50%;
					left: 0;
					width: .6rem;
					height: 1.1rem;
					margin-top: -.4rem;
					background: url(../images/ico_arrow03.svg);
					background-repeat: repeat;
					background-size: auto;
					background-size: contain;
					background-repeat: no-repeat;
					content: "";
				}				
				
			}
			
		}
	}
	
}

@media screen and (min-width: 992px) {

	.sitemap-body{

		& h2{
			margin-bottom: 1.8rem;
			margin-top: 3.2rem;
			padding-left: 1.3rem;
			font-size: 2.4rem;
			position: relative;
			font-weight: normal;
			
			&::before{
				position: absolute;
				content: "";
				left: 0;
				bottom: 0;
				width: 0.4rem;
				height: 100%;
				border-radius: 1.6rem;
				background: $_color04;
				
			}
			
			& > h3{
				padding-top: 0;
			}
			
		}
		
		& h3{
			font-size: 1.8rem;
			padding-bottom: 1.6rem;
			padding-top: 1.6rem;
			border-bottom: 0.3rem dashed $_color07;
			padding-left: 1.6rem;
			font-weight: normal;					

		}
		& ul{
			display: flex;
			& li{
				display: inline-block;
				padding-top: 1.8rem;
				padding-bottom: 1.8rem;
				padding-left: 2.4rem;
				padding-right: 3.2rem;
			}
		}		
		
	}
	
	
}