/* #.p-pagination
   -------------------------------------------------------------------------- */

.pagination{
	margin-top: 2.4rem;
	margin-bottom: 2.4rem;
	justify-content: center;
    align-items: center;
    column-gap: 1rem;
    row-gap: 1.5rem;
    flex-wrap: wrap;
	text-align: center;
	display: flex;    
	
	//max-width: 36rem;
/*
	margin-top: 2.4rem;
	margin-bottom: 2.4rem;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	display: flex;
	justify-content: space-between;
	align-items: center;
*/

	//padding-left: 12.5rem;
	//padding-right: 12.5rem;
		
	&:has( > span.first){
		padding-left: 0;
	}
	&:has( > span.last){
		padding-right: 0;
	}

	
	& a{
		text-decoration: none;
	}
	
	& .first a,
	& .last a{
		width: 2.8rem;
		height: 2.8rem;
		display: flex;
		font-size: 1.2rem;
		justify-content: center;
		align-items: center;
		border-radius: 2.8rem;
		border: 0.1rem solid $_color04;
		padding-bottom: 0.2rem;
		background: #fff;
		color: #333 !important;
	}
	
	& .prev a,
	& .next a{
		padding: 0.55rem 0.9rem;
		display: block;
		background: #fff;
		border-radius: 2.4rem;
		border: 0.1rem solid $_color04;
		color: #333 !important;
	}

	& .page a{
		width: 2.8rem;
		height: 2.8rem;
		display: flex;
		font-size: 1.4rem;
		align-content: center;
		border-radius: 2.8rem;
		border: 0.1rem solid $_color04;
		padding-bottom: 0.2rem;
		background: #fff;
		color: #333 !important;
		justify-content: center;
		align-items: center;
	}	
	
	& .page.current{
		color: #fff !important;
		background: $_color04;
		width: 2.8rem;
		height: 2.8rem;
		display: flex;
		font-size: 1.4rem;
		justify-content: center;
		align-items: center;
		border-radius: 2.8rem;
		border: 0.1rem solid $_color04;
		padding-bottom: 0.2rem;
	}

}


@media screen and (min-width: 992px) {
	
	.pagination{
		//max-width: 56rem;
		margin-top: 4rem;
		margin-bottom: 4rem;
		//padding-left: 19rem;
		//padding-right: 19rem;
		
		&:has( > span.first){
			padding-left: 0;
		}
		&:has( > span.last){
			padding-right: 0;
		}

		& .first a,
		& .last a{
			width: 4.8rem;
			height: 4.8rem;
			font-size: 2rem;
			border: 0.2rem solid $_color04;
			padding-bottom: 0.4rem;
		}
		
		& .prev a,
		& .next a{	
			padding: 1rem 1.6rem;
			border-radius: 0.4rem;
			border: 0.2rem solid $_color04;			
		}
		
		& .page a{
			width: 4.8rem;
			height: 4.8rem;
			font-size: 1.6rem;
			border: 0.2rem solid $_color04;
		}	
		
		& .page.current{
			width: 4.8rem;
			height: 4.8rem;
			font-size: 1.6rem;
			
		}
	
	}
	
}


@media screen and (min-width: 1200px) {
	
	.pagination{
		
	
	}
	
}