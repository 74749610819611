 /* #Wrapper
   -------------------------------------------------------------------------- */
/*doc
---
title: wrapper
name: wrapper
categories: [layout]
---
レイアウト全体を囲うラッパーのスタイルです。
```block
<body>
	<div class="l-wrapper">
		<header class="l-header"></header>
		<article class="l-content"></article>
		<aside class="l-sidebar"></aside>
		<footer class="l-footer"></footer>
	</div>
</body>
```
*/
.l-wrapper {
}