/* #.p-info
   -------------------------------------------------------------------------- */

.p-info{

	&__select{
		.p-btn02,
		.p-btn01{
			margin-bottom: 0.8rem;
			max-height: 4.8rem;
		}
				
		&-form{
			padding-bottom: 2.4rem;
		}
		
		&-box{
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: 0.4rem;
			
			&-wrap{
				position: relative;
				width: 100%;
				margin-right: 1.6rem;
				
				&::after{
					display: inline-block;
					position: absolute;
					top: 50%;
					right: 1.6rem;
					width: .6rem;
					height: 1.1rem;
					margin-top: -.4rem;
					background: url(../images/ico_arrow03.svg);
					background-size: contain;
					background-repeat: no-repeat;
					content: "";
					transform: rotate(90deg);
				}
				
			}			
			
		}
		
		&-txt{
			font-size: 1.8rem;
		}
		
		&-pulldown{
			width: 100%;
			padding: 1rem 0.8rem;
			border: .1rem solid #333;
			border-radius: .4rem;
			outline: 0;
			background: #fff;
			appearance: none;			
		}
		
		&-btn{
			display: inline-block;
			padding: 1rem 1.6rem;
			border: none;
			border-radius: .4rem;
			background-color: $_color04;
			color: #fff;
			font-size: 1.4rem;			
		}
		
		
	}
	
	&__item{
		padding-bottom: 1.6rem;
		border-bottom: 0.1rem dashed #494949;
		padding-top: 1.6rem;
		
		
	}
	
	&__parts{
		display: flex;
		justify-content: space-between;
		padding-bottom: 0.8rem;
		
	}
	
	&__date{
		align-content: center;
	}
	
	&__catarea{
		display: flex;
		gap: 1rem;
		flex-wrap: wrap;
		max-width: 18rem;
/*
	    display: grid;
	    display: grid;
	    grid-template-columns: repeat(2, 1fr);
	    grid-column-gap: 0.8rem;
	    grid-row-gap: 0.4rem;
	    width: min-content;
*/
	}
	
	&__cat{
		padding: 0.2rem 0;
		background: $_color07;
		border-radius: 3.8rem;
		border: 0.2rem solid $_color07;
		width: 6rem;
		display: block;
		text-align: center;
		font-size: 1.2rem;
		font-weight: bold;
		text-shadow: rgb(255, 255, 255) 2px 0px 0px, rgb(255, 255, 255) 1.75517px 0.958851px 0px, rgb(255, 255, 255) 1.0806px 1.68294px 0px, rgb(255, 255, 255) 0.141474px 1.99499px 0px, rgb(255, 255, 255) -0.832294px 1.81859px 0px, rgb(255, 255, 255) -1.60229px 1.19694px 0px, rgb(255, 255, 255) -1.97999px 0.28224px 0px, rgb(255, 255, 255) -1.87291px -0.701566px 0px, rgb(255, 255, 255) -1.30729px -1.51361px 0px, rgb(255, 255, 255) -0.421592px -1.95506px 0px, rgb(255, 255, 255) 0.567324px -1.91785px 0px, rgb(255, 255, 255) 1.41734px -1.41108px 0px, rgb(255, 255, 255) 1.92034px -0.558831px 0px;
  		
  		&--genre{
	  		background: #fff;
	  		width: 11rem;
  		}
  		
  		&--blue{
	  		background: #a9cce6;
	  		border: #a9cce6;
  		}
  		
  		&:last-of-type{
	  		margin-right: 0;
  		}

	}
	
	&__link{
		align-content: center;
	}
	
	&__child{
		background: #fff;
		padding: 1.6rem;
		border-radius: 2rem;
		border: 0.5rem solid #91B786;
		margin-bottom: 3.2rem;
		
		&-ttl{
			font-size: 1.8rem;
			font-weight: bold;
			color: $_child-color01;
			z-index: 10;
			text-align: center;
			
			span{
				position: relative;
				z-index: 10;
				
				&::before{
					position: absolute;
					content: "";
					bottom: 0.2rem;
					left: 50%;
					width: 110%;
					height: 0.4rem;
					margin-left: -55%;
					background: #CDE1DA;
					z-index: -10;
				}				
				
			}
		
			
		}
		
		.p-info__item{
			border-bottom: 0.1rem solid #CDE1DA;
		}
		
		.p-info__date{
			padding-right: 0.6rem;
		}
		
		.p-info__list {
		  padding-bottom: 2.4rem;
		}
		
	}	


	&__ya{
		background: #fff;
		padding: 1.6rem;
		border-top: 0.4rem solid #91B786;
		margin-bottom: 3.2rem;
		
		&-ttl{
			padding: 1rem 0;
			font-size: 1.6rem;
			font-weight: bold;
			color: $_child-color01;
			text-align: center;
			
			
		}
		.p-info__item{
			border-bottom: 0.1rem solid #CDE1DA;
		}
		
		.p-info__date{
			padding-right: 0.6rem;
		}
		
		.p-info__list {
		  padding-bottom: 2.4rem;
		}
		
	}	
	
}


@media screen and (min-width: 992px) {
	
	.p-info{	
		
		&__select{
			display: flex;
			align-items: flex-end;
			padding-bottom: 2rem;
			
			.p-btn02,
			.p-btn01{
				margin-bottom: 0;
				margin-left: 0;
				margin-right: 0;
				padding: 0.9rem 1rem;
				max-height: fit-content;
			}
			
			&-form{
				padding-bottom: 0;
				max-width: 49.6rem;
				display: block;
				width: 100%;
				padding-right: 6rem;
			}	
			
			
			&-btn{
				padding: 1.3rem 1.6rem;
			}		
		}
		
		&__item{
			padding-bottom: 2.4rem;
			border-bottom: 0.1rem dashed #494949;
			padding-top: 2.4rem;
			display: flex;
			justify-content: space-between;
			
/*
			&:first-of-type{
				padding-top: 0rem;
			}
*/
			
		}
		
		&__date{
			width: 14rem;
		}
		
		&__catarea{
			max-width: 27rem;
		}
		
		&__parts{
			order: 2;
			align-items: center;
			padding-bottom: 0;
		}
		
		&__link{
			max-width: 48rem;
			width: 100%;
			display: block;
			margin-right: 4rem;		
		}
		
		&__cat{
			padding: 0.3rem 0;
			width: 13rem;
			font-size: 1.4rem;
			height: fit-content;
			text-shadow: rgb(255, 255, 255) 3px 0px 0px, rgb(255, 255, 255) 2.83487px 0.981584px 0px, rgb(255, 255, 255) 2.35766px 1.85511px 0px, rgb(255, 255, 255) 1.62091px 2.52441px 0px, rgb(255, 255, 255) 0.705713px 2.91581px 0px, rgb(255, 255, 255) -0.287171px 2.98622px 0px, rgb(255, 255, 255) -1.24844px 2.72789px 0px, rgb(255, 255, 255) -2.07227px 2.16926px 0px, rgb(255, 255, 255) -2.66798px 1.37182px 0px, rgb(255, 255, 255) -2.96998px 0.42336px 0px, rgb(255, 255, 255) -2.94502px -0.571704px 0px, rgb(255, 255, 255) -2.59586px -1.50383px 0px, rgb(255, 255, 255) -1.96093px -2.27041px 0px, rgb(255, 255, 255) -1.11013px -2.78704px 0px, rgb(255, 255, 255) -0.137119px -2.99686px 0px, rgb(255, 255, 255) 0.850987px -2.87677px 0px, rgb(255, 255, 255) 1.74541px -2.43999px 0px, rgb(255, 255, 255) 2.44769px -1.73459px 0px, rgb(255, 255, 255) 2.88051px -0.838247px 0px;			
			
		}

		&__date{
			padding-right: 1.2rem;
		}
		
		&__child{
			padding: 3.2rem 6.4rem;
			margin-bottom: 7.4rem;
			
			&-ttl{
				font-size: 2.4rem;
				margin-bottom: 2.4rem;
				
				& span{
					height: 0.6rem;
					&::before{
						height: 0.5rem;
					}
					
				}
				
			}
			
			.p-info__list{
				padding-bottom: 4rem;
			}
			
			.p-info__item{
				display: block;
				border-bottom: 0.2rem solid #CDE1DA;
			}
			
			.p-info__parts{
				order: inherit;
				padding-bottom: 0.8rem;
				justify-content: flex-start;
			}
			
			.p-info__link{
				max-width: 100%;
				margin-right: 0;
			}
			
			.p-btn01{
				font-size: 1.8rem;
				padding: 1.4rem;
				
			}
			
			.p-info__date{
				font-size: 1.8rem;
				width: auto;
			}
					
		}		
		&__ya{
			padding: 3.2rem 6.4rem 4rem;
			margin-bottom: 7.4rem;
			
			&-ttl{
				font-size: 2.4rem;
				padding-bottom: 4rem;
				
			}
			
			.p-info__list{
				padding-bottom: 4rem;
			}
			
			.p-info__item{
				display: block;
				border-bottom: 0.2rem solid #CDE1DA;
			}
			
			.p-info__parts{
				order: inherit;
				padding-bottom: 0.8rem;
				justify-content: flex-start;
			}
			
			.p-info__link{
				max-width: 100%;
				margin-right: 0;
			}
			
			.p-btn01{
				font-size: 1.8rem;
				padding: 1.4rem;
				
			}
			
			.p-info__date{
				font-size: 1.8rem;
			}
					
		}			
				
	}

	
}


@media screen and (min-width: 1200px) {
	
	.p-info{
		&__link{
			//max-width: 48rem;
			max-width: auto;
		}
		
		&__catarea{
			max-width: 41rem;
		}
		
		&__select{
			
			&-form{
				max-width: 49.6rem;
			}
			
			&-txt{
				font-size: 2rem;
			}
			
			
			&-pulldown{
				padding: 1.2rem 1.6rem;
			}
			
			&-btn{
				padding: 1.3rem 2.4rem;
				font-size: 1.6rem;
			}
			
			.p-btn02,
			.p-btn01{
				padding: 1.2rem;
			}
		
		}

	}
}