// ボタンのスタイル
.p-btn {
  &__back {
    margin: auto;
    display: block;
    padding: 0.8rem 3.9rem 0.8rem 6.3rem;
    background: $_color01;
    border: 0.1rem solid #333;
    border-radius: 0.4rem;
    font-size: 1.6rem;
    position: relative;
    margin-top: 3.2rem;

    &::before {
      position: absolute;
      top: 50%;
      left: 3.8rem;
      width: 1.5rem;
      height: 1.5rem;
      margin-top: -.6rem;
      background: url(/common/images/ico_arrow06.svg);
      background-size: contain;
      background-repeat: no-repeat;
      content: "";
      transform: rotate(-180deg);
    }

    @include mq-up('lg') {
      margin-top: 7.2rem;
    }
  }

  &01 {
    font-size: 1.6rem;
    font-weight: bold;
    text-decoration: none;
    text-align: center;
    display: block;
    padding: 0.8rem;
    border: 0.15rem solid $_color04;
    max-width: 23rem;
    margin: auto;
    border-radius: 0.4rem;
    box-shadow: 0px 3px 6px 0px #00000029;
    color: $_color04;
    background: #fff;

    &:visited {
      color: $_color04;
    }

    &--left {
      margin-left: 0;
    }

    @include mq-up('lg') {
      max-width: 24rem;
      border: 0.2rem solid $_color04;
    }
  }

  &02 {
    display: block;
    max-width: 23rem;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 0.8rem;
    text-align: center;
    text-decoration: none;
    background: $_color04;
    border-radius: 0.4rem;
    font-size: 1.6rem;
    color: #fff;
    box-shadow: 0px 3px 6px 0px #00000029;

    &:visited {
      color: #fff;
    }

    &--left {
      margin-left: 0;
    }

    &[target="_blank"]::after {
      color: #fff !important;
    }

    @include mq-up('lg') {
      max-width: 24rem;
    }
  }

  &__child {
    &-back {
      font-weight: bold;
      text-align: center;
      display: block;
      padding: 1rem 1rem 1rem 3rem;
      width: 100%;
      max-width: 22rem;
      position: relative;
      background: #fff;
      border: 0.2rem solid $_child-color02;
      border-radius: 0.4rem;
      text-decoration: none;
      margin: auto;

      &:visited {
        color: #333;
      }

      &::before {
        position: absolute;
        top: 50%;
        left: 2rem;
        width: 1.5rem;
        height: 1.5rem;
        margin-top: -.6rem;
        background: url(/common/images/child_arrow01.png);
        background-size: contain;
        background-repeat: no-repeat;
        content: "";
      }

      @include mq-up('lg') {
        font-size: 1.8rem;
        max-width: 30rem;
        padding: 1.3rem 3rem 1.3rem 6rem;

        &::before {
          left: 3.2rem;
          width: 2rem;
          height: 2rem;
          margin-top: -1rem;
        }
      }
    }
  }
}