/* #main
   -------------------------------------------------------------------------- */
/*doc
---
title: main
name: main
categories: [layout]
---
*/

.l-article {
  .p-signage {
    display: none;
  }

  > time {
    padding-bottom: 1.6rem;
    text-align: right;
    display: block;
  }

  &.page section.categories {
    display: none;
  }

  section.categories {
    clear: both;
    margin-top: 5.6rem;
    margin-bottom: 4rem;
    border-top: 1px solid #666;
    text-align: right;

    header,
    .nodes {
      display: inline-block;
      vertical-align: middle;
      padding: 5px 0;
    }

    header {
      h2 {
        width: 100%;
        margin: 0;
        padding: 0;
        border: none;
        color: #666;
        font-size: 100%;
        font-weight: normal;
        box-shadow: none;
        background: transparent;

        &::before {
          display: none;
        }
      }
    }

    .nodes {
      ul {
        margin: 0;

        li {
          display: inline-block;
          vertical-align: middle;
          margin: 0;
          padding-right: 0.5rem;
          background: none;
          border-bottom: none;
          font-size: 88%;
          font-weight: normal;
          list-style: none;
          line-height: 1.2;

          &:first-of-type {
            border-left: 1px solid #666;
            padding-left: 0.5rem;
          }

          a {
            pointer-events: none;
            text-decoration: none;
            color: $_color04;
            background: #fff;
            padding: 0.2rem 1.4rem;
            border-radius: 1.8rem;
            margin: 0 !important;

            &::before {
              display: none !important;
            }
          }
        }
      }
    }
  }
}

@include mq-down('lg') {
  .l-yield > time {
    padding-bottom: 3.2rem;
  }
}