/* #main
   -------------------------------------------------------------------------- */
/*doc
---
title: main
name: main
categories: [layout]
---
*/

.cke_editable {
  background: #FBF4E4 !important;
  padding: 2.4rem;
}

.l-main {
  /*
  a:not([class])[href],
  a.icon-png,
  a.icon-gif,
  a.icon-jpeg,
  a.icon-jpg {
    position: relative;
    padding-left: 1.2rem;
    padding-right: 0.8rem;
    display: inline-block;
    margin-bottom: 0.8rem;

    &::before {
      position: absolute;
      top: 50%;
      left: 0;
      width: 0.8rem;
      height: 1.1rem;
      display: inline-block;
      margin-top: -0.5rem;
      background: url(../images/ico_arrow03.svg);
      background-size: contain;
      background-repeat: no-repeat;
      content: "";
    }
  }

  ul li > a:not([class])[href],
  ol li > a:not([class])[href] {
    margin-left: 0;
    padding-left: 1rem;
  }

  a[target="_blank"] {
    padding-right: 2rem !important;
  }

  a[target="_blank"]::after {
    content: "\f35d";
    font-weight: 900;
    margin-left: 0.5rem;
    font-size: 1.3rem;
    color: #224a94;
    font-family: "Font Awesome 5 Free";
    background: none;
    speak: none;
    bottom: 0;
    right: 0;
    position: absolute;
  }

  a:not([class])[href]:has(> img)::before,
  a[target="_blank"]:has(> img)::after {
    display: none;
  }
  */

  img {
    max-width: 100%;
    height: auto;

    &:not([class]) {
      padding-bottom: 0.8rem;
    }
  }

  p {
    &:not([class]) {
      padding-bottom: 0.8rem;
      text-align: left;
    }

    &:has(a.p-link01),
    &:has(img) {
      padding-bottom: 0;
    }
  }
}

@include mq-up(lg) {
  .l-main {
    .row .col-lg-6 {
      &:nth-of-type(odd) {
        padding-right: 1.5rem;
      }
      &:nth-of-type(even) {
        padding-left: 1.5rem;
      }
    }
  }
}