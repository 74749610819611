/* #PC Main visual
   -------------------------------------------------------------------------- */
/*doc
---
title: pc-main-visual
name: pc-main-visual
categories: [project]
---
PC表示用メインビジュアルのコンポーネントです。
*/

.p-pc-main-visual {
	display: block;
	padding-bottom: 4rem;

	&__area{
		position: relative;		
	}
	&__control{
		display: flex;
		justify-content: space-between;
		max-width: 74.3rem;
		margin: auto;
	}
	&__list {
		position: relative;
	}
	&__item {
		overflow: hidden;
		margin-left: 4rem;
		padding-bottom: 1rem;
	}
	&__link {
		display: block;
		max-width: 75rem;
		max-height: 30rem;
	}
	
	&__img{
		@include box-shadow;
		border-radius: $_radius02;
		border: 0.2rem solid $_color01;
		width: 100%;
		
	}
	&__play, &__pause {
		background: none;
	    border: 0.2rem solid $_color03;
	    border-radius: $_radius;
	    color: #333;
	    background: $_color03;
	    font-size: 1.4rem;
	    font-weight: bold;
	    padding-right: 2.1rem;
	    padding-left: 2.1rem;
	    &:hover, &:hover::before {
	        transition: 0.3s;
			color: #333;
		    background: #fff;
	    }
	}
	
	&__control-body{
		display: flex;
		width: 100%;
		position: relative;
	}
		
	&__dots{
		position: absolute;
		right: 0;
		top: 0;
	}	
	
	&__play {
		display: none;
	}
	&__pause {
		display: block;
	}
}



@include mq-down(lg) {
	.p-pc-main-visual {
		display: none;
	}
}