/* table
-------------------------------------------------------------------------- */
/* テーブルのレスポンシブスタイル */
.p-table-responsive {
  position: relative;
  display: block;
  width: 100%;
  overflow-x: auto;

  &::before {
    content: '表を左右にスライドできます。';
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    color: #666;
  }

  table {
    margin-top: 2.5rem;
    white-space: nowrap;
  }
}

/* テーブルの基本スタイル */
table {
  border-bottom: 0.2rem solid #348052;
  margin-bottom: 3.2rem;

  thead {
    border-top: 0.2rem solid #348052;

    & + tbody {
      border-top: none;
    }
  }

  &:not(:has(thead)) {
    tbody {
      border-top: 0.2rem solid #348052;
    }
  }

	.has-no-thead {
	  border-top: 0.2rem solid #348052;
	}

  th {
    background: #C5D9A9;
    font-size: 1.4rem;
    font-weight: normal;
    text-align: center;
    width: min-content;
    border: 0.1rem solid #BFBFBF;
    vertical-align: baseline;
    border-top: 0;
  }

  td {
    width: max-content;
    background: #fff;
    border: 0.1rem solid #BFBFBF;
    border-top: 0;
  }

  caption {
    text-align: left;
    padding-bottom: 0.4rem;
    caption-side: top;
  }
}

/* 縦書きテーブルのスタイル */
.p-table-vertical {
  th {
    text-align: left;
  }

  th:has(span) {
    text-align: center;
  }

  th span {
    writing-mode: vertical-rl;
    text-orientation: upright;
    align-content: flex-start;
  }
}

@include mq-down('lg') {
.p-table-vertical th{
	min-width: 5rem;
}

}
/* 大きな画面向けのスタイル */
@include mq-up('lg') {
  .p-table-responsive {
    table {
      //white-space: normal;
    }

    &::before {
      display: none;
    }
  }

  table {
    th {
      text-align: left;
      font-size: 1.6rem;
    }
  }
}