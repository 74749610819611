//.p-findbook__search
.p-findbook__search {
  border: 0.2rem solid $_color03;
  padding: 1.6rem;
  margin-bottom: 3.2rem;
  text-align: center;
  background: #fff;

  &-form {
    padding-bottom: 1.4rem;
    padding-top: 0.8rem;
  }

  &-txt {
    outline: none;
    border: 0.15rem solid $_color04;
    background: #fff;
    border-radius: 0.4rem;
    padding: 0.6rem 1.6rem;
    margin-right: 0.8rem;
    width: calc(100% - 7rem);
  }

  &-btn-search {
    background-color: $_color04;
    color: #fff;
    padding: 0.6rem 1.3rem;
    font-size: 1.6rem;
    border-radius: 0.4rem;
    border: none;
    height: 100%;
  }

  @include mq-up('lg') {
    padding: 2.4rem 7.2rem;
    margin-bottom: 4.8rem;

    &-form {
      padding-bottom: 2.4rem;
      padding-top: 1.6rem;
    }

    &-txt {
      border: 0.2rem solid $_color04;
      padding: 1.6rem 1.6rem;
      margin-right: 1.6rem;
      width: calc(100% - 12rem);
    }

    &-btn-search {
      padding: 1.5rem 3.2rem;
      font-size: 1.8rem;
    }
  }
}