/* p-top -------------------------------------------------------------------------- */
/* トップページのスタイル */
.p-top {
  /* OPACログインボタンのスタイル */
  &__opac-login {
    padding-bottom: 1.6rem;
    text-align: center;

    &--link {
      display: inline-block;
      background-color: $_color04;
      border-radius: $_radius;
      color: #fff;
      font-size: 1.6rem;
      padding: 0.8rem 1.5rem 0.8rem 3.9rem;
      position: relative;
      display: inline-block;
/*
      width: 100%;
      max-width: 16.6rem;
*/
      text-decoration: none;

      &::before {
        position: absolute;
        content: "";
        top: 50%;
        left: 1.8rem;
        width: 1.6rem;
        height: 1.8rem;
        margin-top: -0.8rem;
        background: url(/common/images/ico_login01_2025.svg) no-repeat;
        background-size: contain;
      }

      &:visited {
        color: #fff;
      }
    }
  }
}

/* 大きな画面向けのスタイル */
@include mq-up('lg') {
  .p-top {
    &__opac-login {
      display: none;
    }
  }
}