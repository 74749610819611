//.p-definition01
.p-definition01 {
  dt {
    font-weight: bold;
  }

  @include mq-up('lg') {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 10px;

    dt {
      grid-column: 1 / 2;
      font-weight: bold;
    }

    dd {
      grid-column: 2 / 3;
      margin: 0;
    }
  }
}