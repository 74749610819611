/*
 
@page {
  size: A4 portrait;
}

@media print {
  body {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact; 
	font-size: 0.5rem;
	transform-origin: top left;
	margin: 0 auto;
	transform: scale(0.8);
    
	.container{
		max-width: inherit;
	}

  }
}
*/


/*
@page {
  size: A4 portrait;
}

@media print {
  body {
	font-size: 0.7rem;
    transform: scale(0.8);
    -moz-transform: scale(0.8);
    -webkit-transform: scale(0.8);
    transform-origin: 0 0;
    width: 120%;
    break-inside: avoid;
  }

  .container {
    max-width: inherit; 
  }
}
*/

@page {
  size: A4 portrait;
  margin: 10px;
}

@media print {
  body {
    font-size: 0.7rem;
    transform: scale(0.7);
    -moz-transform: scale(0.7);
    -webkit-transform: scale(0.7);
    transform-origin: 0 0;
    width: calc(100% / 0.7); /* 画面いっぱいに広げるためにcalcを使用 */
    margin: 0; /* 余白をなくす */
    box-sizing: border-box; /* パディングとボーダーを幅に含める */
    overflow: hidden; /* 余白を隠す */
    -webkit-print-color-adjust: exact;
    
  }

  .container {
    width: 100%; /* inheritから100%に変更 */
    max-width: none; /* 最大幅の制限を解除 */
    
  }
  
  .l-main{
	  page-break-before:auto;
  }

  /* 改ページを避けたい要素に適用 */
  .no-break,
  table {
    page-break-inside: avoid;
    break-inside: avoid;
  }  
  
}