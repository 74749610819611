.p-index01 {
  &__item {
    padding-bottom: 1.6rem;
  }

  &__link {
    display: flex;
    padding: 1.2rem 4rem 1.2rem 1rem;
    align-items: center;
    border-radius: 0.4rem;
    text-decoration: none;
    background: #fff;
    position: relative;
    font-size: 1.6rem;
    font-weight: normal;
    height: 100%;

    &:has(img) {
      padding: 1.2rem 4rem 1.2rem 1.6rem;
      min-height: 8.8rem;
    }

    &::before {
      position: absolute;
      top: 50%;
      right: 1rem;
      width: 2.2rem;
      height: 2.2rem;
      margin-top: -1.1rem;
      background: url(/common/images/ico_arrow06.svg);
      background-size: contain;
      background-repeat: no-repeat;
      content: "";
    }

    &:visited {
      color: #333;
    }
  }

  &__img {
    max-width: 4.4rem !important;
    width: 100%;
    margin-right: 1rem;
  }

  &__text {
    font-size: 1.6rem;
    color: #333;
    font-weight: normal;

    &:visited {
      color: #333;
    }
  }

  &__ttl.spopac {
    border-radius: 0.4rem;
    border: 0.2rem solid $_color04;

    a {
      color: $_color04;
      position: relative;
      text-align: center;
      padding: 1.5rem 2rem;
      justify-content: center;

      &::before,
      &::after {
        content: "";
        position: absolute;
      }

      &::after {
        border-top: 2.4rem solid transparent;
        border-right: 2.4rem solid #a9cce6;
        bottom: 0;
        right: 0;
      }

      &::before {
        border-bottom: 2.4rem solid transparent;
        border-left: 2.4rem solid #a9cce6;
        top: 1.1rem;
        left: 0;
      }
    }
  }

  @include mq-up('lg') {
    display: flex;
    width: 100%;
    gap: 24px 48px;
    flex-wrap: wrap;

    &__item {
      width: calc(100% / 3 - 32px);
      padding-bottom: 0;
    }

    &__link {
      padding: 1.5rem 4rem 1.5rem 1rem;
      font-size: 1.8rem;

      &:has(img) {
        padding: 1.2rem 4rem 1.2rem 1rem;
      }

      &::before {
        width: 2.4rem;
        height: 2.4rem;
        margin-top: -1.2rem;
      }
    }

    &__ttl.spopac a {
      &::before {
        top: 1.2rem;
      }
    }
  }
}

.p-index02 {
  &__item {
    padding: 1.6rem;
    background: #fff;
    border-radius: 0.4rem;
    margin-bottom: 2.4rem;
    box-shadow: 0px 3px 6px 0px #00000029;

    @include mq-up('lg') {
      padding: 2.4rem;
      border-radius: 0.8rem;
      margin-bottom: 4rem;
    }
  }

  &__img {
    display: block;
    width: 100%;
    box-shadow: 0px 3px 6px 0px #00000029;
  }

  & .p-heading02 {
    margin-top: 0;
  }

  &__link {
    &:visited {
      color: #333;
    }
  }

  @include mq-up('lg') {
    & .col-lg-6:first-of-type {
      padding-right: 1.5rem;
    }

    & .col-lg-6:last-of-type {
      padding-left: 1.5rem;
    }
  }
}

body.body--child {
  & .p-index01__link {
    border-bottom: 0.25rem solid $_color07;
    border-right: 2rem solid $_color07;
    border-radius: 0;

    &::before {
      right: -1.4rem;
      margin-top: -0.5rem;
      width: 0.7rem;
      height: 1.1rem;
      background: url(/common/images/ico_arrow07.svg);
      background-size: contain;
      background-repeat: no-repeat;
    }

    @include mq-up('lg') {
      border-right: 2.7rem solid $_color07;

      &::before {
        right: -1.8rem;
      }
    }
  }
}

@media print{
	.p-index01__link{
		border: 0.1rem solid #333;
	}
}