/* #Padding
   -------------------------------------------------------------------------- */
/*doc
---
title: padding
name: padding
categories: [utility]
---
```block

```
*/

/* 上 */

.u-pt-none {
	padding-top: 0 !important;
}

.u-pt-tiny {
	padding-top: 4rem !important;
	@media screen and (max-width: 767px) {
		padding-top: 2rem !important;
	}
}

.u-pt-small {
	padding-top: 6rem !important;
	@media screen and (max-width: 767px) {
		padding-top: 3rem !important;
	}
}

.u-pt-medium {
	padding-top: 8rem !important;
	@media screen and (max-width: 767px) {
		padding-top: 4rem !important;
	}
}

.u-pt-large {
	padding-top: 10rem !important;
	@media screen and (max-width: 767px) {
		padding-top: 5rem !important;
	}
}

.u-pt-huge {
	padding-top: 12rem !important;
	@media screen and (max-width: 767px) {
		padding-top: 6rem !important;
	}
}

/* 下 */
.u-pb-none {
	padding-bottom: 0 !important;
}

.u-pb-tiny {
	padding-bottom: 4rem !important;
	@media screen and (max-width: 767px) {
		padding-bottom: 2rem !important;
	}
}

.u-pb-small {
	padding-bottom: 6rem !important;
	@media screen and (max-width: 767px) {
		padding-bottom: 3rem !important;
	}
}

.u-pb-medium {
	padding-bottom: 8rem !important;
	@media screen and (max-width: 767px) {
		padding-bottom: 4rem !important;
	}
}

.u-pb-large {
	padding-bottom: 10rem !important;
	@media screen and (max-width: 767px) {
		padding-bottom: 5rem !important;
	}
}

.u-pb-huge {
	padding-bottom: 12rem !important;
	@media screen and (max-width: 767px) {
		padding-bottom: 6rem !important;
	}
}

/* 上下 */
.u-ptpb-none {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}

.u-ptpb-tiny {
	padding-top: 4rem !important;
	padding-bottom: 4rem !important;
	@media screen and (max-width: 767px) {
		padding-top: 2rem !important;
		padding-bottom: 2rem !important;
	}
}

.u-ptpb-small {
	padding-top: 6rem !important;
	padding-bottom: 6rem !important;
	@media screen and (max-width: 767px) {
		padding-top: 3rem !important;
		padding-bottom: 3rem !important;
	}
}

.u-ptpb-medium {
	padding-top: 8rem !important;
	padding-bottom: 8rem !important;
	@media screen and (max-width: 767px) {
		padding-top: 4rem !important;
		padding-bottom: 4rem !important;
	}
}

.u-ptpb-large {
	padding-top: 10rem !important;
	padding-bottom: 10rem !important;
	@media screen and (max-width: 767px) {
		padding-top: 5rem !important;
		padding-bottom: 5rem !important;
	}
}

.u-ptpb-huge {
	padding-top: 12rem !important;
	padding-bottom: 12rem !important;
	@media screen and (max-width: 767px) {
		padding-top: 6rem !important;
		padding-bottom: 6rem !important;
	}
}


