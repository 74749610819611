/* p-recommend
   -------------------------------------------------------------------------- */

.p-recommend {
	margin-top: 1.6rem;
	&:has(.p-heading02){
		padding-bottom: 3.2rem;
	}

	&__img:has(img){
		max-width: 10rem;
		width: 100%;
		height: auto;
		margin: auto;
		padding-bottom: 0.8rem;
		padding-top: 1.6rem;
		
		& img{
			padding-bottom: 0 !important;
			width: 100%;
			box-shadow: 0px 3px 6px 0px #00000029;
		}
		
	}
	
	&__img.has-img{
		max-width: 10rem;
		width: 100%;
		height: auto;
		margin: auto;
		padding-bottom: 0.8rem;
		padding-top: 1.6rem;
		
		& img{
			padding-bottom: 0 !important;
			width: 100%;
			box-shadow: 0px 3px 6px 0px #00000029;
		}
		
	}
	
	&__book-info{
		padding-bottom: 1.6rem;
	}
	
	&__list{
		padding-top: 1.6rem;
	}

}






@media screen and (min-width: 992px) {
	
	.p-recommend{	
		margin-top: 2.4rem;
		&__img{
			max-width: none;
			width: 100%;
			height: auto;
			margin: auto;
			padding-bottom: 0;
			padding-top: 0;
			
			&:has(img){
				max-width: 13.8rem;
				padding-top: 0rem;
			
			}

			&.has-img{
				max-width: 13.8rem;
				padding-top: 0rem;
			
			}
			
		}
		
		& .p-heading03{
			margin-top: 0;
		}
		
		&__list{
			display: grid;
			//grid-template-columns: repeat(auto-fit, minmax(27rem, 1fr));
			grid-template-columns: repeat(2, 1fr);
			font-size: 1.5rem;
		}
		
	}
}


@media screen and (min-width: 1200px) {
	
	.p-recommend{	
		
		&__list{
			grid-template-columns: repeat(3, 1fr);
		}
		
	}
}