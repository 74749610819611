/* .p-sp-search-nav
   -------------------------------------------------------------------------- */
.p-header__search-nav{
	display: none;
	z-index: 400;
	position: absolute;
	width: 100%;
	height: auto;
	
	&__area{
		padding: 4rem 1.6rem;
		width: 100%;
		background: $_color05;
		
		& .site-search-history-part{
			align-items: flex-start;
			display: flex;
			margin-bottom: 4rem;
			justify-content: center;
			
			& .site-search-history{
				position: absolute;
				width: calc(100% - 16px);
				background-color: #ffffffd1;
				padding: 1rem 0.5rem;
				z-index: 10;
				display: none !important;
			}
			
		}
		
		
		& .site-search-keyword{
			position: relative;
			max-width: 700px;
			width: 100%;
			
			& input#s_keyword{
				width: calc(100% - 16px);
				padding: 1rem 0.8rem;
				border: .1rem solid #333;
				border-radius: .4rem !important;
				outline: 0;
				background: #fff;
				appearance: none;			
			}
		}
		
		& #search-button{
			display: inline-block;
			border: none;
			background-color: $_color04;
			color: #fff;
			font-size: 1.4rem;
			min-width: 7rem;
			position: static;
			padding: 1.1rem 1.6rem;
			border-radius: .4rem;		
		}
	
	}
	
	&__close{
		font-size: 1.6rem;
		text-align: center;
		padding-bottom: 1rem;
		padding-top: 1rem;
		border: 0.2rem solid #fff;
		background: $_color01;
		cursor: pointer;
		width: 11rem;
		margin: auto;
		
		& span{
			padding-left: 2.4rem;
			position: relative;
			
			
			&::before{
				display: inline-block;
				position: absolute;
				top: 50%;
				left: 0;
				width: 2.4rem;
				height: 2.4rem;
				margin-top: -1rem;
				background: url(../images/ico_menu03.svg);
				background-size: contain;
				background-repeat: no-repeat;
				content: "";					
			}
			
		}
		
		&-area{
			position: absolute;
			width: 100%;
			background: #333;
			opacity: 30%;
			z-index: 500;
		}
		
		
		
	}
	
	
	
}


@media screen and (min-width: 992px) {
	
	
}
