/* #Breadcrumb
   -------------------------------------------------------------------------- */
/*doc
---
title: breadcrumb
name: breadcrumb
categories: [project]
---
パンくずリストのコンポーネントです。横幅を超えると、折り返さず省略されます。
```block
<ol class="p-breadcrumb">
	<li class="p-breadcrumb__item">
		<a href="#" class="p-breadcrumb__link">home</a>
	</li>
	<li class="p-breadcrumb__item">
		<a href="#" class="p-breadcrumb__link">page1</a>
	</li>
	<li class="p-breadcrumb__item">page2</li>
</ol>
```
*/
// パンくずリストのスタイル
.p-breadcrumb {
  margin-bottom: 1.6rem;
  font-size: 1.2rem;

  .page:visited {
    color: #333;
  }

  .separator {
    display: none;
  }

  // 2つ目以降のリストの前に`>`を表示する
  .page:not(:first-of-type):before {
    content: "/";
    margin: 0 0.5rem 0 0.75rem;
  }

  // 992px以上の画面サイズに対してスタイルを適用
  @include mq-up('lg') {
    margin-bottom: 1.8rem;
    font-size: 1.4rem;
  }
}