//.p-footer
.p-footer {
  background: $_color04;

  &__cont {
    padding-top: 1.6rem;
    padding-bottom: 1.4rem;
    color: #fff;

    .container {
      position: relative;
    }

    @include mq-up('lg') {
      padding-top: 2.4rem;
    }
  }

  &__pagetop {
    position: relative;
  }

  &__pagetop-link {
    display: none;
    position: fixed;
    bottom: 3.2rem;
    right: 1.5rem;
    z-index: 100;
    color: #fff;
    background: $_color04;
    font-weight: bold;
    padding-top: 1.2rem;
    padding-bottom: 0.5rem;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
    border-radius: $_radius;
    font-size: 1rem;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    height: 4.8rem;
    width: 4.8rem;
    @include box-shadow;
    z-index: 100;

    &::before {
      display: inline-block;
      position: absolute;
      top: 0.5rem;
      left: 50%;
      width: 1.4rem;
      height: 0.8rem;
      margin-left: -0.65rem;
      background: url(/common/images/ico_pagetop01.svg);
      background-size: contain;
      background-repeat: no-repeat;
      content: "";
    }

    @include mq-up('lg') {
      font-size: 1.7rem;
      padding-top: 1.9rem;
      padding-bottom: 1.1rem;
      padding-left: 0.8rem;
      padding-right: 0.8rem;
      bottom: 3.2rem;
      right: 3.2rem;
      height: 8.5rem;
      width: 8.5rem;
      line-height: 1;
      align-content: center;

      &::before {
        top: 1.2rem;
        left: 50%;
        width: 2rem;
        height: 1.1rem;
        margin-left: -0.9rem;
      }
    }
  }

  &__info {
    text-align: center;
    padding-bottom: 1.6rem;

    @include mq-up('lg') {
      display: flex;
      justify-content: center;
      padding-bottom: 1.8rem;

      &-addr {
        padding-right: 3.2rem;
      }
      
    }
      &-tell{
	      a{
	      	pointer-events: none;
	      	color: #fff;
	      	text-decoration: none;
		  }
      }
    
  }

  &__copyright {
    text-align: center;
    font-size: 0.8rem;
    display: block;

    @include mq-up('lg') {
      font-size: 1.2rem;
    }
  }

  &__menu {
    display: flex;
    justify-content: center;
    padding-bottom: 2.4rem;

    &-item {
      position: relative;
      padding-left: 1rem;
      padding-right: 1.1rem;

      &:last-of-type {
        padding-right: 0;
      }

      &::before {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 0;
        width: 0.6rem;
        height: 1.1rem;
        margin-top: -0.4rem;
        background: url(../images/ico_arrow04.svg);
        background-size: contain;
        background-repeat: no-repeat;
        content: "";
      }

      @include mq-up('lg') {
        padding-right: 6.2rem;

        &::before {
          width: 0.7rem;
        }

        &:last-of-type {
          padding-right: 0;
        }
      }
    }

    &-link {
      color: #fff;

      &:visited {
        color: #fff;
      }
    }
  }

  &__logo {
    padding-bottom: 1.6rem;
    text-align: center;

    &-link {
      display: inline-block;
      margin-right: 6.5rem;

      @include mq-up('lg') {
        margin-right: 9.5rem;
      }
    }

    &-img {
      max-width: 24.4rem;

      @include mq-up('lg') {
        max-width: none;
      }
    }

    @include mq-up('lg') {
      padding-bottom: 2.4rem;
    }
  }
}

@media print{
	.p-footer {
		background: #fff;
		&__cont{
			color: #333 !important;
		}
		.p-footer__menu-link{
			color: #333 !important;
		}
	}
}