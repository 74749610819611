 /* #Header
   -------------------------------------------------------------------------- */
/*doc
---
title: header
name: header
categories: [layout]
---
`<header>`タグのスタイルです。
ロゴやグローバルナビゲーションのレイアウトを含むことはできますが、
コンポーネント自体はobject/projectレイヤーで定義します。
```block
<body>
	<header class="l-header">
		<div class="p-pc-header hidden-xs hidden-sm">
			<div class="p-pc-header__head">
				<h1 class="p-pc-header__logo">
					<a href=""></a>
				</h1>
			</div>
		</div>
		<nav class="p-pc-header__body">
			<ul class="p-global-nav">
				<li class="p-global-nav__item"></li>
				<li class="p-global-nav__item"></li>
				<li class="p-global-nav__item"></li>
			</ul>
		</nav>
	</header>
</body>
```
*/
.l-header {
}