.p-link-list01 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;

  &__item {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    margin-left: 1.4rem;

    &__link {
      padding-left: 1.4rem;
      position: relative;
      display: inline-block;

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        margin-top: -0.5rem;
        background-image: url(/common/images/ico_arrow03.svg);
        background-repeat: no-repeat;
        width: 0.8rem;
        height: 1.1rem;
      }
    }
  }

  &__link {
    padding-left: 1.4rem;
    position: relative;
    display: inline-block;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -0.5rem;
      background-image: url(/common/images/ico_arrow03.svg);
      background-repeat: no-repeat;
      width: 0.8rem;
      height: 1.1rem;
    }
  }
}