.l-footer {
  padding-top: 10.4rem;
  background: $_color05;
}

@include mq-up('lg') {
  .l-footer {
    padding-top: 15.6rem;
  }
}


@media print{
	.l-footer {
		background: #fff;
	}
}