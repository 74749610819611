/* #Base
   -------------------------------------------------------------------------- */
/**
 * Baseレイヤーには、Mainレイヤー(_main.scss)と同様に、プロジェクトにおける基本的なベーススタイルを定義します。
 * concret5での構築において、先頭に.ccm-pageクラスを付けたくない場合のみBaseレイヤーに定義します。
 * concrete5のインターフェースへの干渉を防ぐため、出来る限りMainレイヤー(_main.scss)に定義するようにしてください。
 */
/**
 */
 
 @font-face {
 font-family: 'NotoSansJP';
 font-weight: normal;
 src: url(../font/NotoSansJP/NotoSansJP-Medium.woff2) format('woff2');
}
@font-face {
 font-family: 'NotoSansJP';
 font-weight: bold;
 src: url(../font/NotoSansJP/NotoSansJP-Bold.woff2) format('woff2');
}
 
html {
	font-size: 62.5%;
	box-sizing: border-box;
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

.visually-hidden-focusable {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.visually-hidden-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto;
    display: block;
    text-align: center;
    background: #eee;
}


body {
	font-family: "NotoSansJP", "Hiragino Sans W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif !important;
	-webkit-text-size-adjust: 100% !important;
	text-align: justify !important;
	-webkit-font-smoothing: antialiased !important;
	-moz-osx-font-smoothing: grayscale !important;
	line-height: 1.5 !important;
	font-size: 1.4rem !important;
	color: $_color-txt !important;
	background: #ffffff !important;
	-webkit-print-color-adjust: exact;
}

@media screen and (min-width: 992px) {
	
	body {
		font-size: 1.6rem !important;
	}
	
}

p:not([class]):has(a) {
    text-align: left;
}

/* 閲覧支援ツール 文字サイズ変更 */
.f-font-size-small {
	font-size: 50.8% !important;
}
.f-font-size-medium {
	font-size: 62.5% !important;
}
.f-font-size-large {
	font-size: 72.5% !important;
}

/* 閲覧支援ツール 色変更 */
/* 黒 */
.f-color-black {
	background: #000 !important;
	color: #dfde4c !important;
	border-color: #fff !important;
	& a,a .f-color-black {
		color: #fff !important;
	}
	& .c-button {
		background: #fff !important;
		color: #000 !important;
		&:hover {
			color: #fff !important;
			background: #000 !important;
			border: 1px solid #fff !important;
		}
	}
}
/* 青 */
.f-color-blue {
	background: #0b24fb !important;
	color: #dfde4c !important;
	border-color: #fff !important;
	& a,a .f-color-blue {
		color: #fff !important;
	}
	& .c-button {
		background: #fff !important;
		color: #0b24fb !important;
		&:hover {
			color: #fff !important;
			background: #0b24fb !important;
			border: 1px solid #fff !important;
		}
	}
}
/* 黄 */
.f-color-yellow {
	background: #fffd38 !important;
	color: #000 !important;
	border-color: #0b24fb !important;
	& a,a .f-color-yellow {
		color: #0b24fb !important;
	}
	& .c-button {
		background: #0b24fb !important;
		color: #fffd38 !important;
		&:hover {
			color: #0b24fb !important;
			background: #fffd38 !important;
			border: 1px solid #0b24fb !important;
		}
	}
}


@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    /* Safari専用のスタイル */
    #content:focus {
      outline: none !important;
    }

    /* 子要素のフォーカススタイルを保持 */
    #content *:focus {
      outline: revert !important;
    }
  }
}