//.p-event__info
.p-event__info {
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;

  &-ttl {
    display: inline-block;
    font-size: 1.4rem;
    font-weight: normal;
    color: #2e6d9d;
    padding: 0.2rem 0.8rem;
    border: 0.15rem solid;
    background: #fff;
    margin-right: 0.8rem;
    margin-bottom: 0.4rem;
  }

  @include mq-up('lg') {
    display: flex;
    align-items: center;

    &-ttl {
      font-size: 1.6rem;
      border: 0.2rem solid;
      margin-bottom: 0;
    }
  }
}


form.event-search{
	display: none;
}

.article-search > form {
  display: none;
}
.article-search + .result {
  display: none;
}