//.cms-site-search
.cms-site-search {
  padding: 0;
  width: 100%;

  .site-search-history {
    top: 8rem;

    @include mq-up('lg') {
      top: 9rem;
    }
  }

  form {
    border: 0;
  }

  .site-search-categories {
    display: none;
  }

  .search-form {
    display: flex;
    align-items: end;

    .site-search-keyword {
      border: 0;
      width: 100%;

      dt label {
        font-size: 1.8rem;

        @include mq-up('lg') {
          font-size: 2rem;
        }
      }

      input#s_keyword {
        width: calc(100% - 16px);
        padding: 1rem 0.8rem;
        border: 0.1rem solid #333;
        border-radius: 0.4rem !important;
        outline: 0;
        background: #fff;
        appearance: none;
        margin-top: 0.4rem;
        line-height: normal;

        @include mq-up('lg') {
          padding: 1.2rem 1.6rem;
        }
      }

      .site-search-history {
        min-width: calc(100% - 16px);

        li {
          padding-left: 1.4rem;
        }
      }
    }

    input[type="submit"] {
      position: static;
      padding: 1rem 1.6rem;
      border-radius: 0.4rem;
      line-height: 2.3rem;

      @include mq-up('lg') {
        padding: 1.2rem 2.4rem;
        line-height: 26px;
      }
    }
  }

  input[type="submit"] {
    display: inline-block;
    border: none;
    background-color: $_color04;
    color: #fff;
    font-size: 1.4rem;
    width: auto;

    @include mq-up('lg') {
      font-size: 1.6rem;
    }
  }
}