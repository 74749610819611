/* #.p-link-btn
   -------------------------------------------------------------------------- */

.p-link-btn01{
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	width: 100%;	
	
	&__item{
		display: inline-block;
		background: #fff;
		width: calc(50% - 19px / 2);
		margin-bottom: 1.8rem;
		border-radius: 0.4rem;
		border: 0.15rem solid #494949;
		@include box-shadow;
	}
	
	&__link{
		text-decoration: none;
		padding: 0.5rem;
		height: 100%;
		display: block;
		
		&:visited{
			color: $_color-txt;
		}		
		&-ttl{
			position: relative;
			background: #fff;
			width: 100%;
			height: 100%;
			display: block;
			margin: auto;
			padding-bottom: 0.8rem;
			padding-top: 0.8rem;
			padding-left: 1rem;
			padding-right: 1.5rem;
			align-content: center;
			
			
			&::before{
				display: inline-block;
				position: absolute;
				top: 50%;
				left: 0;
				width: .6rem;
				height: 1.1rem;
				margin-top: -.4rem;
				background: url(../images/ico_arrow03.svg);
				background-size: contain;
				background-repeat: no-repeat;
				content: "";
			
			}
			
			&::after{
				display: inline-block;
				position: absolute;
				bottom: 0;
				right: 0;
				width: 1.815rem;
				height: 1.815rem;
				margin-top: -.9rem;
				background: url(../images/ico_btn01.svg);
				background-size: contain;
				background-repeat: no-repeat;
				content: "";
			}
			
		}
		
	}
	
}


@media screen and (min-width: 992px) {
	
	.p-link-btn01{
		justify-content: flex-start;
		&__item{
			width: calc( 25% - 64px / 4);	
			border: 0.2rem solid #494949;	
			margin-right: 1.6rem;
			margin-bottom: 2.4rem;
			
			&:nth-of-type(4n){
				margin-right: 0;
			}				
		}
		
		&__link{
	
			&-ttl{
				padding-bottom: 1rem;
				padding-top: 1rem;
				padding-left: 1.5rem;	
				&::before{
					width: .7rem;
					left: 0.5rem;
				
				}
			}
			
		}
		
	}
	
}