.p-global-nav {
  display: none;
  background: $_color05;
  z-index: 300;
  position: absolute;
  width: 100%;

  &__list {
    padding-bottom: 4rem;
    padding-top: 1.6rem;
  }

  &__list02 {
    display: none;
    padding-bottom: 1.6rem;
    background: #fff;
  }

  &__item {
    margin-bottom: 0.3rem;

    @include mq-up('lg') {
      margin-bottom: 0.4rem;
    }
  }

  &__link-index {
    display: block;
    padding-left: 2.7rem;
    padding-bottom: 1.8rem;
    padding-top: 1.8rem;
    position: relative;

    &:visited {
      color: #333;
    }

    &::after {
      position: absolute;
      content: "";
      bottom: 0;
      right: 0;
      left: 0;
      margin: 0 auto;
      width: 95%;
      border: 0;
      height: 2px;
      background-image: repeating-linear-gradient(90deg, $_color03 0px, $_color03 1.5px, rgba(0, 0, 0, 0) 1.5px, rgba(0, 0, 0, 0) 4px);

      @include mq-up('lg') {
        width: 97%;
      }
    }

    &::before {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 1.6rem;
      width: 0.6rem;
      height: 1.3rem;
      margin-top: -0.5rem;
      background: url(/common/images/ico_arrow03.svg);
      background-size: contain;
      background-repeat: no-repeat;
      content: "";
    }
  }

  &__link {
    display: block;
    padding-left: 3.2rem;
    padding-bottom: 1rem;
    padding-top: 1rem;
    position: relative;

    &:visited {
      color: #333;
    }

    &::before {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 1.6rem;
      width: 0.6rem;
      height: 1.3rem;
      margin-top: -0.5rem;
      background: url(/common/images/ico_arrow03.svg);
      background-size: contain;
      background-repeat: no-repeat;
      content: "";
    }

    @include mq-up('lg') {
      width: 33.33%;
      padding-bottom: 1.2rem;
      padding-top: 1.2rem;
      padding-right: 3.2rem;

      &::before {
        top: 2rem;
        margin-top: 0;
      }

      &-page {
        display: flex;
        flex-wrap: wrap;
      }
    }
  }

  &__link-page {
    padding-left: 1.4rem;
    padding-right: 1.4rem;

    @include mq-up('lg') {
      padding-left: 2.6rem;
      padding-right: 2.6rem;
    }
  }

  &__cate,
  &__link-cate {
    position: relative;
    padding: 1rem 5.4rem 1rem 1.6rem;
    background: $_color04;
    font-size: 1.4rem;
    color: #fff;
    display: block;
    width: 100%;
    text-decoration: none;
    cursor: pointer;

    &:visited {
      color: #fff;
    }

    &::after {
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 1.6rem;
      width: 2.2rem;
      height: 2.2rem;
      margin-top: -1.1rem;
      background: url(/common/images/ico_arrow05.svg);
      background-size: contain;
      background-repeat: no-repeat;
      content: "";
    }

    @include mq-up('lg') {
      padding: 1.6rem 7.2rem 1.6rem 2.4rem;
      font-size: 1.6rem;
    }
  }

  &__cate {
    &:after {
      transform: rotate(90deg);
    }
  }

  &__close {
    font-size: 1.6rem;
    text-align: center;
    padding-bottom: 1rem;
    padding-top: 1rem;
    border: 0.2rem solid #fff;
    background: $_color01;
    cursor: pointer;
    margin-top: 6.4rem;
    margin-bottom: 7.2rem;

    span {
      padding-left: 2.4rem;
      position: relative;

      &::before {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 0;
        width: 2.4rem;
        height: 2.4rem;
        margin-top: -1rem;
        background: url(/common/images/ico_menu03.svg);
        background-size: contain;
        background-repeat: no-repeat;
        content: "";
      }
    }
  }
}

.p-global-subnav {
  &__list {
    display: grid;
    gap: 1.4rem;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 4rem;

    @include mq-up('lg') {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }

  &__item {
  }

  &__link {
    padding: 1rem 1.4rem;
    background: #fff;
    border: 0.15rem solid $_color04;
    width: 100%;
    display: block;
    text-decoration: none;
    position: relative;

    &::after {
      position: absolute;
      top: 50%;
      right: 0.4rem;
      width: 0.8rem;
      height: 1.1rem;
      display: inline-block;
      margin-top: -0.5rem;
      background: url(/common/images/ico_arrow03.svg);
      background-size: contain;
      background-repeat: no-repeat;
      content: "";
    }
  }
}