/* #Site search
   -------------------------------------------------------------------------- */
/*doc
---
title: site search
name: site search
categories: [project]
---
サイト内検索のコンポーネントです。
*/

@media screen and (min-width: 992px) {
	.p-site-search {
		float: left;
		@include clearfix;
		&__input {
			float: left;
		}
		&__txt {
			width: 30rem;
			outline : none;
			border: 0.1rem solid $_color-border;
			border-right: none;
			font-size: 1.4rem;
			border-radius: $_radius 0 0 $_radius;
			padding-left: 1rem;
			height: 2.25em;
		}
		&__btn {
			float: left;
			border-radius: 0 $_radius $_radius 0;
			font-weight: bold;
			color: #ffffff;
			background: $_color01;
			padding-left: 1.5rem;
			padding-right: 1.5rem;
		}
	}
}

@media screen and (max-width: 991px) {

}


/* 検索結果 */

.p-site-search {
	&__result-body {
		margin-bottom: 4rem;
	}
	&__result-input {
		position: relative;
		&:before {
			position: absolute;
			top: 50%;
			right: 1.6rem;
			margin-top: -1.6rem;
			content: "\f002";
			font-family: FontAwesome;
			color: $_color-outline;
			z-index: 2;
			cursor: pointer;
			font-size: 1.9rem;
		}
	}
	&__result-txt {
		width: calc(100% - 5rem);
		height: 5rem;
		border: 0.1rem solid $_color-border !important;
		border-radius: 0 !important;
	}
	&__result-open {
		position: absolute;
		top: 0;
		right: 0;
		z-index: 1;
		background:$_color01;
		color: $_color06;
		text-indent: -10rem;
		width: 5rem;
		height: 5rem;
		border: none !important;
		border-radius: 0 !important;
	}
}

.cms-site-search-pages.pages {
    word-wrap: break-word;
}