.p-header {
  &__gmenu,
  &__gclose {
    padding-top: 3.2rem;
    position: relative;
    font-size: 1rem;
    color: $_color04;
    width: 4rem;
    text-align: center;
  }

  &__gmenu {
    &::before {
      position: absolute;
      content: "";
      top: 0;
      left: 50%;
      width: 3.2rem;
      height: 3.2rem;
      margin-left: -1.6rem;
      background: url(/common/images/ico_menu04.svg);
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  &__gclose {
    &::before {
      position: absolute;
      content: "";
      top: 0;
      left: 50%;
      width: 3.2rem;
      height: 3.2rem;
      margin-left: -1.6rem;
      background: url(/common/images/ico_menu05.svg);
      background-repeat: no-repeat;
      background-size: contain;
    }

    display: none;
  }

  &__search {
    padding-top: 3.2rem;
    position: relative;
    font-size: 1rem;

    &::before {
      position: absolute;
      content: "";
      top: 0;
      left: 50%;
      width: 3.2rem;
      height: 3.2rem;
      margin-left: -1.6rem;
      background: url(/common/images/ico_search01.svg);
      background-repeat: no-repeat;
      background-size: contain;
    }

    &-close {
      padding-top: 3.2rem;
      position: relative;
      font-size: 1rem;
      display: none;
      min-width: 5rem;
      text-align: center;

      &::before {
        position: absolute;
        content: "";
        top: -0.5rem;
        left: 50%;
        width: 4.8rem;
        height: 4.8rem;
        margin-left: -2.4rem;
        background: url(../images/ico_menu03.svg);
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
  }

  @include mq-up('lg') {
    &__gmenu,
    &__gclose {
      padding: 2rem 2.6rem 2rem 8.2rem;
      border: 0.2rem solid $_color04;
      color: $_color04;
      font-weight: bold;
      border-radius: 0.8rem;
      position: relative;
      cursor: pointer;
      font-size: 1.6rem;
      width: 17.6rem;
    }

    &__gmenu {
      &::before {
        position: absolute;
        content: "";
        top: 50%;
        left: 2.63rem;
        width: 3.2rem;
        height: 3.2rem;
        margin-top: -1.6rem;
        background: url(/common/images/ico_menu01.svg);
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

    &__gclose {
      &::before {
        position: absolute;
        content: "";
        top: 50%;
        left: 2.63rem;
        width: 2.4rem;
        height: 2.4rem;
        margin-top: -1.2rem;
        background: url(/common/images/ico_menu02.svg);
        background-repeat: no-repeat;
        background-size: contain;
      }

      display: none;
    }

    &__search {
      margin-right: 2.4rem;
      padding-top: 4rem;
      font-size: 1.6rem;

      &::before {
        width: 3.98rem;
        height: 3.98rem;
        margin-left: -1.99rem;
      }

      &-close {
        margin-right: 2.4rem;
        font-size: 1.6rem;
        min-width: 8rem;
        padding-top: 4rem;

        &::before {
          top: -1rem;
          width: 6.4rem;
          height: 6.4rem;
          margin-left: -3.2rem;
        }
      }
    }
  }
}