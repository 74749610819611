.p-imglayout {
  &__i1 {
    display: flex;
    justify-content: center;
  }

  &__i2 {
    display: flex;
    justify-content: left;
    gap: 2.4rem;
  }

  & p {
    display: inline-block;
  }
}