.l-section--none {
  padding-bottom: 0;
}

.l-section--tiny {
  padding-bottom: 4rem;
}

.l-section--small {
  padding-bottom: 6rem;
}

.l-section {
  padding-bottom: 4rem;
}

.l-section--large {
  padding-bottom: 10rem;
}

.l-section--huge {
  padding-bottom: 12rem;
}

@include mq-down('md') {
  .l-section--tiny {
    padding-bottom: 1rem;
  }

  .l-section--small {
    padding-bottom: 3rem;
  }

  .l-section {
    padding-bottom: 3.2rem;
  }

  .l-section--large {
    padding-bottom: 4rem;
  }

  .l-section--huge {
    padding-bottom: 5rem;
  }
}