/* pc-header -------------------------------------------------------------------------- */
.p-pc-header {
	
	display: none;
	
}

@include mq-up('lg'){
	
	.p-pc-header {
		display: block;
		background: #fff;
		border-top: 0.8rem solid $_color03;
		padding-top: 0.8rem;
		padding-bottom: 2.4rem;
		
		&__logo{
			margin-top: 1.6rem;
		}
		
		& .container{
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
		
		&__logo-link{
			display: block;
		}
		
		&__login{
			padding: 3rem 3rem 1rem 3rem;
			background-color: $_color04;
			border-radius: 0.8rem;
			margin-right: 1.6rem;
			text-align: center;
			font-weight: bold;
			border: 0.2rem solid $_color04;
			color: #fff;
			position: relative;
			text-decoration: none;
			color: #fff;
			position: relative;
			&:visited{
				color: #fff;
			}
		
			&::before{
				position: absolute;
				content: "";
				top: 0.95rem;
				left: 50%;
				width: 1.6rem;
				height: 1.8rem;
				margin-left: -0.8rem;
				background: url(/common/images/ico_login01_2025.svg);
				background-repeat: no-repeat;
				background-size: contain;
			}			
		}
		
		&__utility{
			&-top{
				padding-bottom: 2.4rem;
			}
			
			&-bottom{
				display: flex;
				justify-content: end;
			}
		}
		
	}
}