.p-frame01 {
  border: 0.2rem solid $_color09;
  border-radius: 0.4rem;
  margin-bottom: 3.4rem;
  margin-top: 1.8rem;

  &__head {
    background: $_color09;
    padding: 0.4rem 1.4rem 0.4rem 1.8rem;
  }

  &__ttl {
    position: relative;
    font-size: 1.6rem;
    font-weight: normal;

    &::before {
      position: absolute;
      content: "";
      top: 0;
      left: -0.8rem;
      width: 0.3rem;
      height: 100%;
      border-radius: 1.4rem;
      background: #f7f2ec;
    }
  }

  &__body {
    padding: 1rem;
    background: #fff;
  }

  @include mq-up('lg') {
    margin-top: 2.4rem;

    &__head {
      padding: 0.6rem 1.8rem 0.6rem 2.7rem;
    }

    &__ttl {
      font-size: 2rem;
    }

    &__body {
      padding: 1.6rem;
    }
  }
}

.p-frame02 {
  padding: 0.8rem 1.6rem;
  border: 0.2rem solid #bfbfbf;
  display: inline-block;
  background: #f5f5f5;
}