/* p-ya
   -------------------------------------------------------------------------- */

.p-ya {
  &-main-visual {
    padding-bottom: 0.8rem;
    max-width: 30rem;
    margin: auto;
    width: 100%;

    &__img {
      display: block;
      width: 100%;
    }
  }

  &-menu {
    padding-top: 2.4rem;

    &__item {
      display: flex;
      justify-content: center;
    }

    &__link {
      max-width: 16rem;
      width: 100%;
      display: block;
    }
  }
}

@include mq-up('sm') {
  .p-ya {
    &-main-visual {
      max-width: 100%;
    }

    &-menu {
      padding-top: 3.2rem;

      &__list {
        //max-width: 80rem;
        margin: auto;
      }

      &__link {
        max-width: 32rem;
      }
    }
  }
}

@include mq-up('md') {
  .p-ya {
    // Add styles for min-width: 768px if needed
  }
}

@include mq-up('lg') {
  .p-child {
    &-main-visual {
      max-width: 100%;
    }
  }
}