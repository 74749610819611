/* p-open-day
-------------------------------------------------------------------------- */

.p-open-day {
	
	&__body{
		padding: 1.6rem 0.8rem;
		background: #fff;
		border: 0.15rem solid #BFBFBF;
		display: flex;
		margin-top: 0.8rem;
		
		&-today{ 
			display: block;
			align-content: center;
		
			span{
				padding: 0.8rem 0.4rem;
				background: $_color07;
				text-align: center;
				border-radius: 3.6rem;
				margin-right: 1.2rem;
			}
		}
		
		&-item01{
			text-decoration: none;
			display: flex;
			align-items: center;
			padding: 0rem 2rem;
			border: 0.15rem solid $_color04;
			border-radius: 0.4rem;
			margin-right: 1.2rem;
			box-shadow: 0px 3px 6px 0px #00000029;
			min-height: 4.2rem;
		}
		
		&-item02{
			display: flex;
			justify-content: center;
			align-items: center;
		}
		 
	}
	
	&__status{
		&-text {
			font-size: 1.8rem;
		}
		
		&-mark {
			padding-right: 0.8rem;
			
			&--img {
				width: 2.2rem;
				vertical-align: middle;
				display: block;
			}
			
		}
		
		
	}
	

}


@media screen and (min-width: 992px) {
	
	.p-open-day {
	
		&__body{
			border: none;
			position: relative;
			padding: 1.6rem 4rem !important;
			margin-top: 0;
			display: block;
			
			&-today{
				position: absolute;
				content: "";
				left: -2rem;
				top:-1.2rem;
				
				span{
					padding: 1rem 0.6rem;
					border-radius: 4.4rem;
				}
				
			}
			
			&-item01{
				padding: 1rem 2rem;
				justify-content: center;
				margin-right: 0;
				
			}
			
		}
		
		&__notes{
			padding-top: 0.8rem;
			text-align: center;
		}
		
		&__status{
			&-text {
				font-size: 2rem;
			}
			
			&-mark {
				padding-right: 0.8rem;
				
				&--img {
					width: 2.7rem;
				}
				
			}
			
			
		}		
	
	}
	
}