/* #.p-link
-------------------------------------------------------------------------- */

.p-link01 {
	position: relative;
	padding-left: 1.2rem;
	display: inline-block;
	margin-bottom: 0.8rem;
// 	padding-right: 0.8rem;
	margin-left: 0.4rem;
	
	&::before{
		position: absolute;
		top: 50%;
		left: 0;
		width: 0.8rem;
		height: 1.1rem;
		display: inline-block;
		margin-top: -0.5rem;
		background: url(../images/ico_arrow03.svg);
		background-size: contain;
		background-repeat: repeat;
		background-repeat: no-repeat;
		content: "";	
	}	
	
	&--bottom{
		&::before{
			transform: rotate(90deg);
			margin-top: -0.4rem;
		}
	}
	
	&--top{
		&::before{
			transform: rotate(-90deg);
		}		
	}
	
	&[target="_blank"]{
		//padding-right: 2rem !important;
	}
	
	&[target="_blank"]::after {
	    position: relative;
	    margin-left: .5rem;
	    padding-right: 1.5rem;
	    padding-right: 1.8rem;
	    padding-bottom: .3rem;
	    background-image: url(/common/images/ico_up-right.svg);
	    background-position: right center;
	    background-size: 1.3rem 1.3rem;
	    background-repeat: no-repeat;
	    content: "(外部リンク)";
	}	
	
	
}

a:not([href]){
	text-decoration: none;
}

.ss-alignment a[href]:not(.p-btn01):not(.p-btn02):not(.p-info__link):not(.p-link-btn01__link),
.cke_show_borders a[href]:not(.p-btn01):not(.p-btn02):not(.p-info__link):not(.p-link-btn01__link),
section.editor-area :not(footer) a[href]:not(.p-btn01):not(.p-btn02):not(.p-info__link):not(.p-link-btn01__link),
.ss-preview-column a[href]:not(.p-btn01):not(.p-btn02):not(.p-info__link):not(.p-link-btn01__link),
article.l-article.pageslayout a[href]:not(.p-btn01):not(.p-btn02):not(.p-info__link):not(.p-link-btn01__link),
l-article page article.faq-question + article.body a[href]:not(.p-btn01):not(.p-btn02):not(.p-info__link):not(.p-link-btn01__link){
	position: relative;
	padding-left: 1.2rem;
	display: inline-block;
	margin-bottom: 0.8rem;
// 	padding-right: 0.8rem;
	margin-left: 0.4rem;
	
	&::before{
		position: absolute;
		top: 50%;
		left: 0;
		width: 0.8rem;
		height: 1.1rem;
		display: inline-block;
		margin-top: -0.5rem;
		background: url(../images/ico_arrow03.svg);
		background-size: contain;
		background-repeat: repeat;
		background-repeat: no-repeat;
		content: "";	
	}	
	
	&[target="_blank"]{
// 		padding-right: 2rem !important;
	}
	
	&[target="_blank"]::after {
	    position: relative;
	    margin-left: .5rem;
	    padding-right: 1.5rem;
	    padding-right: 1.8rem;
	    padding-bottom: .3rem;
	    background-image: url(/common/images/ico_up-right.svg);
	    background-position: right center;
	    background-size: 1.3rem 1.3rem;
	    background-repeat: no-repeat;
	    content: "(外部リンク)";
	}	
	
	
}


.ss-alignment ul.p-ul-list01 li > a[href],
.ss-alignment ol.p-ol-list01 li >  a[href],
article.l-article.pageslayout ul.p-ul-list01 li > a[href],
article.l-article.pageslayout ol.p-ol-list01 li >  a[href],
l-article page article.faq-question + article.body ul.p-ul-list01 li > a[href],
l-article page article.faq-question + article.body ol.p-ol-list01 li > a[href]{
		padding-left: 2.8rem !important;
		margin-left: 0.5rem !important;
}

.ss-alignment a:has(>img), 
article.l-article.pageslayout a:has(>img),
l-article page article.faq-question + article.body a:has(>img){
	padding-left: 0;
	&:before {
	 	content: none;
 	}
}

a[href^="mailto:"][target=_blank]::after {
	content: none !important;
    background-image: none !important;;
    margin-left: 0 !important;;
    padding-right: 0 !important;;
    padding-bottom: 0 !important;;
}


@media screen and (min-width: 992px) {
	
	.p-link01{
		
		
	}
	
}