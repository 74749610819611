/* #SP Main visual
   -------------------------------------------------------------------------- */
/*doc
---
title: sp-main-visual
name: sp-main-visual
categories: [project]
---
スマホ表示用メインビジュアルのコンポーネントです。
*/

/* スマホ表示用メインビジュアルのスタイル */
.p-sp-main-visual {
  display: none;
}

@include mq-down(lg) {
  .p-sp-main-visual {
    display: block;
    padding-bottom: 3.2rem;

    &__area {
      position: relative;
    }

    &__control {
      display: flex;
      justify-content: space-between;
    }

    &__list {
      position: relative;
    }

    &__item {
      overflow: hidden;
      width: 60rem;
      margin-left: 2.5rem;
      padding-bottom: 1rem;
    }

    &__play,
    &__pause {
      background: none;
      border: 0.2rem solid $_color03;
      border-radius: $_radius;
      color: #333;
      background: $_color03;
      font-size: 1rem;
      padding: 0 1.8rem;

      &:hover,
      &:hover::before {
        transition: 0.3s;
        color: #333;
        background: #fff;
      }
    }

    &__control-body {
      display: flex;
      width: 100%;
      position: relative;
    }

    &__dots {
      position: absolute;
      right: 0;
      top: 0;
    }

    &__play {
      display: none;
    }

    &__pause {
      display: block;
    }

    &__img {
      width: 100%;
      position: relative;
      top: 0;
      left: 0;
      transform: translate3d(0, 0, 0);
      @include box-shadow;
      border-radius: 0.5rem;
      border: 0.15rem solid $_color01;
    }

    .slick-next {
      right: 2.4rem;
      z-index: 1;
    }

    .slick-prev {
      left: unset;
      z-index: 1;
    }
  }
}

@include mq-down(md) {
  .p-sp-main-visual {
    &__item {
      margin-left: 2rem;
      width: 80vw;
    }

    .slick-prev {
      left: 2rem;
      z-index: 1;
    }

    .slick-next {
      left: unset;
      right: 2rem;
    }
  }
}