/* #Ul-list
   -------------------------------------------------------------------------- */
/*doc
---
title: ul-list
name: ul-list
categories: [project]
---
箇条書きリストのコンポーネントです。
*/

ul.p-ul-list01 {
  text-indent: 0;
  $parent: &;
  &__item, > li {
    padding-left: 1.7rem;
    position: relative;
    margin-bottom: 1rem;
    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 0.5rem;
      height: 0.5rem;
      background: #348052;
      border-radius: $_radius;
      top: 1rem;
      left: 0.5rem;
    }
  }

  &--side {
    font-size: 0;
    text-align: left;

    & li {
      display: inline-block;
      padding-right: 1.6rem;
      font-size: 1.4rem;
    }
  }
}

ul.p-ul-list02 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  text-indent: 0;
  $parent: &;
  &__item, > li {
    padding-left: 1.7rem;
    position: relative;
    margin-bottom: 1rem;
    &:before {
      content: "※";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

@include mq-up('lg') {
  ul.p-ul-list01 {
    &--side {
      & li {
        font-size: 1.6rem;
      }
    }
  }
}