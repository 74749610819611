/* #Color
   -------------------------------------------------------------------------- */
/*doc
---
title: color
name: color
categories: [component]
---
文字サイズ変更のコンポーネントです。
*/

.c-color {
	@include clearfix;
	&--black {
		background: #000 !important;
		color: #dfde4c !important;
		border-color: #ffffff !important;
		& a {
			color: #ffffff !important;
		}
	}
	&--blue {
		background: #0b24fb !important;
		color: #dfde4c !important;
		border-color: #ffffff !important;
		& a {
			color: #ffffff !important;
		}
	}
	&--yellow {
		background: #fffd38 !important;
		color: #000 !important;
		border-color: #0b24fb !important;
		& a {
			color: #0b24fb !important;
		}
	}
	&__btn {
		color: $_color-txt;
		background: #fff;
		border: 0.1rem solid #333;
		padding-top: 0.4rem;
		padding-bottom: 0.4rem;
		padding-left: 1.4rem;
		padding-right: 1.4rem;
		border-radius: $_radius;
		text-align: center;
		font-size: 1.2rem;
		
		&.is-active{
			background: #333;
			color: #fff;
		}
	}
}