/* #Margin
   -------------------------------------------------------------------------- */
/*doc
---
title: margin
name: margin
categories: [utility]
---
マージンで余白の管理をします。常に下方向にだけ余白をとります。
`<section>`要素を使うようなセクションの余白はlayout/_section.scssで管理します。
* `.u-mb`は常に余白をとります。
* `.u-mb-gain`は768px以上で余白を指定します。
* `.u-mb-lose`は768px以上で余白を無くします。
```block
<div class="u-mb-medium">
	<p>Lorem ipsum</p>
</div>
<div class="u-mb-gain-medium">
	<p>Lorem ipsum</p>
</div>
<div class="u-mb-lose-medium">
	<p>Lorem ipsum</p>
</div>
```
*/
/* 常に余白を指定するクラス。 */
.u-mb-none {
  margin-bottom: 0 !important;
  @media screen and (min-width: 768px) {
    margin-bottom: 0 !important;
  }
}

.u-mb-tiny {
  margin-bottom: 1.5rem !important;
  @media screen and (min-width: 768px) {
    margin-bottom: 1.5rem !important;
  }
}

.u-mb-small {
  margin-bottom: 2rem !important;
  @media screen and (min-width: 768px) {
    margin-bottom: 2rem !important;
  }
}

.u-mb-medium {
  margin-bottom: 1.5rem !important;
  @media screen and (min-width: 768px) {
    margin-bottom: 3rem !important;
  }
}

.u-mb-large {
  margin-bottom: 2rem !important;
  @media screen and (min-width: 768px) {
    margin-bottom: 4rem !important;
  }
}

.u-mb-huge {
  margin-bottom: 2.5rem !important;
  @media screen and (min-width: 768px) {
    margin-bottom: 5rem !important;
  }
}

.u-mt-none {
  margin-top: 0 !important;
  @media screen and (min-width: 768px) {
    margin-top: 0 !important;
  }
}

.u-mt-tiny {
  margin-top: 1.5rem !important;
  @media screen and (min-width: 768px) {
    margin-top: 1.5rem !important;
  }
}

.u-mt-small {
  margin-top: 2rem !important;
  @media screen and (min-width: 768px) {
    margin-top: 2rem !important;
  }
}

.u-mt-medium {
  margin-top: 1.5rem !important;
  @media screen and (min-width: 768px) {
    margin-top: 3rem !important;
  }
}

.u-mt-large {
  margin-top: 2rem !important;
  @media screen and (min-width: 768px) {
    margin-top: 4rem !important;
  }
}

.u-mt-huge {
  margin-top: 2.5rem !important;
  @media screen and (min-width: 768px) {
    margin-top: 5rem !important;
  }
}

/* 768px以上で余白を指定するクラス。 */
.u-mb-gain-tiny {
  margin-bottom: 0 !important;
  @media screen and (min-width: 768px) {
    margin-bottom: 1rem !important;
  }
}

.u-mb-gain-small {
  margin-bottom: 0 !important;
  @media screen and (min-width: 768px) {
    margin-bottom: 2rem !important;
  }
}

.u-mb-gain-medium {
  margin-bottom: 0 !important;
  @media screen and (min-width: 768px) {
    margin-bottom: 3rem !important;
  }
}

.u-mb-gain-large {
  margin-bottom: 0 !important;
  @media screen and (min-width: 768px) {
    margin-bottom: 4rem !important;
  }
}

.u-mb-gain-huge {
  margin-bottom: 0 !important;
  @media screen and (min-width: 768px) {
    margin-bottom: 5rem !important;
  }
}

.u-mt-gain-tiny {
  margin-top: 0 !important;
  @media screen and (min-width: 768px) {
    margin-top: 1rem !important;
  }
}

.u-mt-gain-small {
  margin-top: 0 !important;
  @media screen and (min-width: 768px) {
    margin-top: 2rem !important;
  }
}

.u-mt-gain-medium {
  margin-top: 0 !important;
  @media screen and (min-width: 768px) {
    margin-top: 3rem !important;
  }
}

.u-mt-gain-large {
  margin-top: 0 !important;
  @media screen and (min-width: 768px) {
    margin-top: 4rem !important;
  }
}

.u-mt-gain-huge {
  margin-top: 0 !important;
  @media screen and (min-width: 768px) {
    margin-top: 5rem !important;
  }
}


/* 768px以上で余白を無くすクラス。 */
.u-mb-lose-tiny {
  margin-bottom: 1rem !important;
  @media screen and (min-width: 768px) {
    margin-bottom: 0 !important;
  }
}

.u-mb-lose-small {
  margin-bottom: 1.5rem !important;
  @media screen and (min-width: 768px) {
    margin-bottom: 0 !important;
  }
}

.u-mb-lose-medium {
  margin-bottom: 2rem !important;
  @media screen and (min-width: 768px) {
    margin-bottom: 0 !important;
  }
}

.u-mb-lose-large {
  margin-bottom: 2.5rem !important;
  @media screen and (min-width: 768px) {
    margin-bottom: 0 !important;
  }
}

.u-mb-lose-huge {
  margin-bottom: 3rem !important;
  @media screen and (min-width: 768px) {
    margin-bottom: 0 !important;
  }
}
.u-mt-lose-tiny {
  margin-top: 1rem !important;
  @media screen and (min-width: 768px) {
    margin-top: 0 !important;
  }
}

.u-mt-lose-small {
  margin-top: 1.5rem !important;
  @media screen and (min-width: 768px) {
    margin-top: 0 !important;
  }
}

.u-mt-lose-medium {
  margin-top: 2rem !important;
  @media screen and (min-width: 768px) {
    margin-top: 0 !important;
  }
}

.u-mt-lose-large {
  margin-top: 2.5rem !important;
  @media screen and (min-width: 768px) {
    margin-top: 0 !important;
  }
}

.u-mt-lose-huge {
  margin-top: 3rem !important;
  @media screen and (min-width: 768px) {
    margin-top: 0 !important;
  }
}