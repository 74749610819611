/* #Ol-list
   -------------------------------------------------------------------------- */
/*doc
---
title: ol-list
name: ol-list
categories: [project]
---
番号付きリストのコンポーネントです。
*/

ol.p-ol-list01 {
	counter-reset: number 0;
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	margin-left: 1.5rem;
	margin-right: 1.5rem;
	padding-left: 2rem;
	&__item, > li {
		margin-top: 0.5rem;
	    margin-bottom: 0.5rem;
	    text-indent: -1.5rem;
	    
	    & a{
		    padding-left: 2.4rem !important
	    }
	    
		&:before {
			content: none;
			content: "";
			counter-increment: number 1;
		    content: counter(number) ".";
		    color: #348052;
		    font-weight: bold;
		}
	}

}