@charset "utf-8";

/* color
---------------------------------------------------------- */
//グレー
$_color01: #D9D9D9;

//薄いグレー
$_color02: #dddddd;

//緑
$_color03: #ACCB82;



//青
$_color04: #2E6D9D;

//ベージュ
$_color05: #FBF4E4;

//黄色
$_color06: #EDE399;


$_color07: #C5D9A8;

//薄い水色
$_color08: #e6f3fa;

//フレーム緑
$_color09: #C5D9A9;

//子供
//茶色
$_child-color01: #6F5B44;

//緑
$_child-color02: #466445;

//YA
//茶色
$_ya-color01: #5D4D4B;


//文字色
$_color-txt: #333333;
$_color-text: #333333;

/*ボーダー*/
$_color-border01: #cccccc;

// ボーダー
$_color-border: #d9d9d9;

// ボーダー 明るめ
$_color-border02: #aaaaaa;

$_color-outline: #ffffff;

/* 重要 */
$_color-important01: #E69700;

//欧文フォント
$_font-english: 'Poppins', sans-serif;

//Font Awesome
$_font-awesome: "Font Awesome 5 Free";

// 角丸
$_radius: 0.4rem;

$_radius02: 0.8rem;

// transition
$_transition: 0.3s;

/* text-decoration: none;
---------------------------------------------------------- */
@mixin txt-deco-none {
	&,&:link,&:visited,&:hover,&:active {
		text-decoration: none !important;
	}
}

/* color: $_color-txt;
---------------------------------------------------------- */
@mixin color-txt {
	&,&:link,&:visited,&:hover,&:active {
		color: $_color-txt !important;
	}
}

//clearfix
@mixin clearfix {
	&:after {
		content:" ";
	    display:block;
	    clear:both;
	}
}

@mixin hover-opacity {
	&:hover {
		opacity: 0.7;
	}
}

// 影を付けるスタイル
@mixin box-shadow {
	box-shadow:0px 2px 6px 1px rgba(0, 0, 0, 0.15);
	-moz-box-shadow:0px 2px 6px 1px rgba(0, 0, 0, 0.15);
	-webkit-box-shadow:0px 2px 6px 1px rgba(0, 0, 0, 0.15);
	/* IE8（outlineで代替） */
	outline-width: 1px \9;
	outline-style: solid \9;
	outline-color: #d8d6d1 \9;
}

// 回転
@mixin rotate90 {
	transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	-moz-transform: rotate(90deg);
	-webkit-transform: rotate(90deg);
	-o-transform: rotate(90deg);
}

// 白
$_color-base: #fff;

// 明るいグレー
$_color-light: #fdfdfd;

// 薄いグレー
$_color-main: #f5f5f5;

// 濃いグレー
$_color-accent01: #888;




// 矢印
$_arrow01: url("../images/arw01.svg");
$_arrow02: url("../images/arw02.svg");

// テーブル
$_color-table-th:$_color-main;
$_color-table-border-top:$_color-accent01;



// 幅（ width-main + 8 + width-aside + 8 + width-aside-padding = $_width-wrapper ）
$_width-wrapper: 1000px;
$_width-main: 710px;
$_width-aside: 250px; //管理画面側でも最小の広さを指定
$_width-aside-padding: 24px;

// centercolumn,leftcolumn,rightcolumnの共通余白
$_contents-bottom: 100px;

// hover時の背景
$_hover-bg-color: $_color-light;

// ブレークポイント
$breakpoint-up: (
  'sm': 'screen and (min-width: 576px)',
  'md': 'screen and (min-width: 768px)',
  'lg': 'print,screen and (min-width: 992px)',
  'xl': 'screen and (min-width: 1200px)',
) !default;

// max-width
$breakpoint-down: (
  'msm': 'screen and (max-width: 350px)',	
  'sm': 'screen and (max-width: 575px)',
  'md': 'screen and (max-width: 767px)',
  'lg': 'screen and (max-width: 991px)',
  'xl': 'screen and (max-width: 1199px)',
) !default;





// リンク
@mixin link-style {
	&, &:link  {
		color: $_color-text !important;
		text-decoration: underline !important;
	}
	&:visited {
		color: $_color-text !important;
	}
	&:hover,&:focus {
		color:  $_color-text !important;
		text-decoration: none !important;
	}
}

// レスポンシブ
@mixin mq-up($breakpoint: md) {
  @media #{map-get($breakpoint-up, $breakpoint)} {
    @content;
  }
}

@mixin mq-down($breakpoint: md) {
  @media #{map-get($breakpoint-down, $breakpoint)} {
    @content;
  }
}

