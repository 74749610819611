/* #.p-btn01
   -------------------------------------------------------------------------- */

.p-heading-top{
	
	font-size: 1.8rem;
	padding-bottom: 1.8rem;
	text-align: center;
	font-weight: normal;
}


.p-heading01__signage{
	text-align: center;
	font-size: 5.4rem;
	padding-top: 4rem;
	padding-bottom: 4rem;	
}


.p-heading01{
	position: relative;
	padding: 1.2rem 1.9rem;
	background: #fff;
	border-top-left-radius: 0.4rem;
	border-top-right-radius: 0.4rem;
	border-top: 0.1rem solid $_color02;
	border-right: 0.1rem solid $_color02;
	border-left: 0.1rem solid $_color02;
	font-size: 2rem;
	font-weight: normal;
	border-bottom: 0.25rem solid $_color04;
	margin-bottom: 1.6rem;
	
	&::after{
		position: absolute;
		content: "";
		bottom: 0;
		right: 0;
		border-top: 1.42rem solid transparent;
		border-right: 1.42rem solid $_color03;		
	}
	
}

.p-heading02,
.l-main--format article h2:not([class]),
.cke_editable h2:not([class]){
	margin-bottom: 1.6rem;
	margin-top: 2.4rem;
	padding: 0.6rem 1.4rem;
	font-size: 1.8rem;
	position: relative;
	font-weight: normal;
	background: #fff;
	
	&::before{
		position: absolute;
		content: "";
		left: 0.6rem;
		bottom: 13%;
		width: 0.4rem;
		height: 70%;
		border-radius: 1.6rem;
		background: $_color04;
		
	}
	
}

.p-heading02--findbook{
	padding-left: 2.8rem;
	font-size: 1.8rem;
	display: inline-block;
	position: relative;
	margin-bottom: 1.8rem;
	font-weight: normal;
	
	&::before{
		display: inline-block;
		position: absolute;
		top: 50%;
		left: 0;
		width: 2.4rem;
		height: 2.3rem;
		margin-top: -1.1rem;
		background: url(/common/images/ico_search01.svg);
		background-repeat: repeat;
		background-size: contain;
		background-repeat: repeat;
		background-repeat: no-repeat;
		content: "";		
	}
}


.p-heading03,
.l-main--format article h3:not([class]),
.cke_editable h3:not([class]){
	margin-top: 2.4rem;
	margin-bottom: 0.8rem;
	padding-bottom: 0.5rem;
	position: relative;
	font-weight: normal;
	font-size: 1.6rem;
		
	&::before{
		position: absolute;
		content: "";
		width: 100%;
		height: 0.15rem;
		bottom: -0.1rem;
		left: 0;
		background: #BFBFBF;
	}
	&::after{
		position: absolute;
		content: "";
		width: 10%;
		height: 0.15rem;
		bottom: -0.1rem;
		left: 0;
		background: $_color04;
		
	}
	
}


.p-heading04,
.l-main--format article h4:not([class]),
.cke_editable h4:not([class]){
	margin-bottom: 0.8rem;
	margin-top: 1.6rem;
	padding-bottom: 0.1rem;
	font-size: 1.5rem;
	border-bottom: 0.1rem dashed  $_color04;
	font-weight: bold;
	
}

.p-heading05,
.l-main--format article h5:not([class]),
.cke_editable h5:not([class]){
	font-size: 1.4rem;
	margin-top: 1.6rem;
	margin-bottom: 0.8rem;
	padding-left: 1.2rem;
	font-weight: normal;
	position: relative;

	&::before{
		content: '';
		background-color: #2E6D9D;
		width: 0.8rem;
		height: 0.8rem;
		position: absolute;
		left: 0;
		margin-top: -0.4rem;
		top: 50%;
	}
}


body.body--child{
	.p-heading01{
		position: relative;
		padding: 1.2rem 1.9rem;
		background: #fff;
		color: $_child-color01;
		font-size: 2rem;
		font-weight: normal;
		margin-bottom: 1.6rem;
		border-radius: 0;
		border: none;

		&::before{
			position: absolute;
			content: "";
			top: 0;
			left: 0;
			border-right: 2.4rem solid transparent;
			border-top: 2.4rem solid $_color06;		
		}
		
		&::after{
			position: absolute;
			content: "";
			bottom: 0;
			right: 0;
			border-top: 2.4rem solid transparent;
			border-right: 2.4rem solid $_color03;		
		}		
	}
	
	.p-heading02,
	h2:not([class]){
		border-bottom: 0.4rem solid $_color06;
		padding-bottom: 0.4rem;
		padding-left: 1rem;
		position: relative;
		
		&::before{
			display: none;
		}
		
		&::after{
			position: absolute;
			content: "";
			width: 8rem;
			height: 0.4rem;
			bottom: -0.4rem;
			left: 0;
			background: $_color03;
		}
		
		
		
	}
	
	.p-heading03,
	h3:not([class]){
		
		border-bottom: 0.4rem dashed $_color03;
		
		&::before,
		&::after{
			display: none;
		}
		
		& span{
			&::after{
				display: none;
			}
		}
		
	}
	
	
}


@media screen and (min-width: 992px) {
	
	.p-heading-top{
		font-size: 3.2rem;
		padding-bottom: 2.4rem;

	}


	.p-heading01{
		position: relative;
		padding: 1.35rem 2.4rem;
		border-top-left-radius: 0.8rem;
		border-top-right-radius: 0.8rem;
		border-top: 0.2rem solid $_color02;
		border-right: 0.2rem solid $_color02;
		border-left: 0.2rem solid $_color02;
		font-size: 2.6rem;
		border-bottom: 0.3rem solid $_color04;
		margin-bottom: 2.4rem;
		
		&::after{
			position: absolute;
			content: "";
			bottom: 0;
			right: 0;
			border-top: 3.2rem solid transparent;
			border-right: 3.2rem solid $_color03;		
		}
	}


	.p-heading02,
	.l-main--format article h2:not([class]),
	.cke_editable h2:not([class]){
		margin-bottom: 2.4rem;
		margin-top: 3.2rem;
		padding: 0.8rem 2.4rem;
		font-size: 2.4rem;
		position: relative;
		font-weight: normal;
		
		&::before{
			position: absolute;
			content: "";
			left: 1rem;
			bottom: 14%;
			width: 0.5rem;
			height: 70%;
			border-radius: 1.6rem;
			background: $_color04;
			
		}
		
	}

	.p-heading02--findbook{
		padding-left: 3.3rem;
		font-size: 2.4rem;
		margin-bottom: 1.6rem;
		
		&::before{
			width: 2.9rem;
			height: 2.8rem;
			margin-top: -1.3rem;
	
		}
	}
	
	.p-heading03,
	.l-main--format article h3:not([class]),
	.cke_editable h3:not([class]){
		margin-bottom: 1.8rem;
		font-size: 2rem;
		position: relative;
		font-weight: normal;
			
		&::before{
			position: absolute;
			content: "";
			width: 100%;
			height: 0.2rem;
			bottom: -0.05rem;
			left: 0;
			background: #BFBFBF;
		}
		&::after{
			position: absolute;
			content: "";
			width: 10%;
			height: 0.2rem;
			bottom: -0.05rem;
			left: 0;
			background: $_color04;
		}
		
	}
	
	
	.p-heading04,
	.l-main--format article h4:not([class]),
	.cke_editable h4:not([class]){
		margin-bottom: 1.6rem;
		margin-top: 1.6rem;
		padding-bottom: 0.2rem;
		font-size: 1.8rem;		
	}

	.p-heading05,
	.l-main--format article h5:not([class]),
	.cke_editable h5:not([class]){
		font-size: 1.6rem;
		margin-bottom: 0.8rem;
		margin-top: 1.6rem;
		padding-left: 1.4rem;
	
		&::before{
			margin-top: -0.3rem;
		}
	}	
	
	body.body--child{
		.p-heading01{
			position: relative;
			padding: 1.35rem 2.4rem;
			font-size: 2.6rem;
			margin-bottom: 2.4rem;
			border-radius: 0;
			border: none;
			
			&::before{
				position: absolute;
				content: "";
				top: 0;
				left: 0;
				border-right: 3.2rem solid transparent;
				border-top: 3.2rem solid $_color06;		
			}
			
			&::after{
				position: absolute;
				content: "";
				bottom: 0;
				right: 0;
				border-top: 3.2rem solid transparent;
				border-right: 3.2rem solid $_color03;		
			}
	
		}
		
		.p-heading02,
		h2:not([class]){
			border-bottom: 0.6rem solid $_color06;

			&::after{
				width: 9rem;
				height: 0.6rem;
				bottom: -0.6rem;

			}
			
			
		}		
		
		
	}	
	
	
}