/* p-child
   -------------------------------------------------------------------------- */

.p-child {
  &-main-visual {
    padding-bottom: 0.8rem;
    max-width: 30rem;
    margin: auto;
    width: 100%;

    &__img {
      display: block;
      width: 100%;
    }

    @include mq-up('sm') {
      max-width: 100%;
    }
  }

  &-menu {
    &__img {
      display: block;
      width: 100%;
    }

    &__list {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 8rem 1.4rem;
      position: relative;

      &::before {
        position: absolute;
        content: "";
        bottom: 38%;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        max-width: 29.2rem;
        height: 9.6rem;
        background: url(../images/child_sp-parts01_2025.png);
        background-size: contain;
        background-repeat: no-repeat;
      }

      @include mq-up('sm') {
        &::before {
          bottom: 42%;
        }
      }

      @include mq-up('md') {
        &::before {
        bottom: 40%;
        max-width: 56rem;
        height: 16rem;
        left: 55%;
        }
      }

      @include mq-up('lg') {
        display: flex;

        &::before {
          display: none;
        }
      }
    }
  }

  &-submenu {
    padding-bottom: 3.2rem;

    &__ttl {
      font-size: 1.8rem;
      text-align: center;
      padding-bottom: 1.2rem;
      margin-bottom: 3.2rem;
      position: relative;
      color: $_child-color01;

      &::after {
        position: absolute;
        content: "";
        bottom: 0;
        left: 50%;
        margin-left: -2.75rem;
        width: 5.5rem;
        height: 0.5rem;
        border-radius: 1rem;
        background: $_child-color02;
        display: block;
      }

      @include mq-up('lg') {
        font-size: 2.4rem;
        padding-bottom: 1.5rem;
        margin-bottom: 4rem;

        &::after {
          margin-left: -3.2rem;
          width: 6.4rem;
        }
      }
    }

    @include mq-up('lg') {
      padding-bottom: 8rem;

      & .row {
        max-width: 90rem;
        margin: auto;
      }

      &__link {
        max-width: 32rem;
        width: 100%;
        margin: auto;
        display: block;
      }
    }
  }
}


img.d-none.d-lg-block.p-child-menu__parts {
    margin: auto;
}