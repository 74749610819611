/* #Ol-list
   -------------------------------------------------------------------------- */
/*doc
---
title: ol-list
name: ol-list
categories: [project]
---
番号付きリストのコンポーネントです。
*/

.p-open-schedule {
	text-align: center;
	&__item {
		padding-top: 1rem;
		padding-bottom: 1rem;
		padding-left: 1rem;
		padding-right: 1rem;
		background: $_color02;
		border-radius: $_radius;
		margin-bottom: 3rem;
	}
	&__ttl {
		margin-top: 0;
	}
	&__body {
		margin-bottom: 0.56rem;
	}

	&__status-text {
		font-size: 1.6rem;
		display: block;
	}
	&__notes {
		font-size: 1.4rem;
		font-weight: normal;
		&-open, &-close, &-memo {
			font-size: 1.4rem;
		}
		& p{
			padding-bottom: 0.1rem !important;
			text-align: center !important;
		}
		
	}
	&__link-item {
		font-size: 1.4rem;
	}
	&__link {
		margin-bottom: 0;
	}
	&__calendar-tbl {
		table-layout: fixed;
		height: 100%;
		width: 100%;
	}
	&__date{
		display: none;
	}
	&__day-parts01{
		font-size: 1.6rem;
		font-weight: normal;
	}
	&__week {
		table-layout: fixed;
		text-align: center;
		padding-top: 1.6rem;
		padding-bottom: 1.6rem;
		background: #fff;
		border: none;
		font-weight: bold;
		
	}
	&__calendar-item {
		vertical-align: baseline;
		border: 0.1rem solid #BFBFBF;
		padding-top: 0.5rem;
		padding-bottom: 1.5rem;
		padding-left: 1rem;
		padding-right: 1rem;
		&--now {
			border: 0.4rem solid #900000;
			font-weight: bold;
		}
		&--close {
			background: #eee !important;
		}
		&--sun {
			background: #f0d6d5;
		}
		&--sat {
			background: #d8ebed;
		}
		&--empty{
			background: #eee;
		}
	}
	&__calendar-inner {
		height: 100%;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		padding-right: 0.5rem;
		padding-left: 0.5rem;
	}
	&__calendar-day {
		font-weight: bold;
		font-size: 3.6rem;
		margin-bottom: 0.74rem;
	}
	&__calendar-data {
		min-height: 6.7rem;
		padding-left: 0;
	}
	
	&__calendar-parts01{
		margin-right: 0.6rem;
	}
	&__calendar-month {
		font-size: 3.6rem;
	}
	&__calendar {
		padding-top: 0.75rem;
		padding-bottom: 2.4rem;	
	}
	&__calendar-tbl {
		table-layout: fixed;
		height: 100%;
		width: 100%;
		border-bottom: 0;
	}
	&__btn .btn-prev {
		float: left;
		margin-bottom: 0 !important;
		width: 40%;
	}
	&__btn .btn-next {
		float: right;
		margin-bottom: 0 !important;
		width: 40%;
	}
	&__calendar-head {
		border-top: 0.2rem solid $_color04;
		border-bottom: 0.2rem solid $_color04;
	}	
	
	&__holiday{
		display: flex;
		width: 20%;
		justify-content: center;
		margin-bottom: 0.4rem;
		img{
			padding-right: 1.4rem;
			padding-bottom: 0 !important;
			padding-top: 0.4rem;
		}
		
	}
	
	&__navigation, &__btn{
		display: flex;
		align-items: end;
		margin-bottom: 2.4rem;
		font-size: 2.6rem;
	}
	&__navigation01,&__navigation02,&__today{
		width: 30%;
	}
	&__next-btn{
		@include txt-deco-none;
		float: right;
		right: 0;
		bottom: 0;
		position: relative;
	    padding-right: 2.6rem;
	    margin-bottom: 0.4rem;
	    color: #333;
	    
	    &:visited{
		    color: #333;
	    }
	    
	    &:before{
		   content: "";
		   position: absolute;
		   top: 1rem;
		   right: 0;
		   background-size: 2.3rem 2.3rem;
		   background-image: url("/common/images/ico_arrow06.svg");
		   background-repeat: no-repeat;
		   width: 2.3rem;
		   height: 2.3rem;
	    }
	}
	&__prev-btn{
		@include txt-deco-none;
		float: left;
	    color: #333;
		position: relative;
	    padding-left: 2.6rem;
	    margin-bottom: 0.4rem;
	    
	    &:visited{
		    color: #333;
	    }

	    &:before{
		   content: "";
		   position: absolute;
		   top: 1rem;
		   left: 0;
		   background-size: 2.3rem 2.3rem;
		   background-image: url("/common/images/ico_arrow06.svg");
		   transform: rotateY( 180deg );
		   background-repeat: no-repeat;
		   width: 2.3rem;
		   height: 2.3rem;
	    }
	}
	&__today{
		font-weight: bold;
		width: 100%;
	}
	&__today-item{
		font-weight: bold;
		font-size: 2.6rem;
	}
}

.p-open-schedule__btn p.p-open-schedule__prev {
	display: none;
}
.p-open-schedule__btn p.p-open-schedule__next {
	display: none;
}

@include mq-up(lg) {
	.p-open-schedule {
		&__status-mark-img {
			width: 3rem;
			margin: auto;
			display: block;
			min-height: 3rem;
		}
	}
}


@include mq-down(lg) {
	.p-open-schedule {
		margin-top: 2.4rem;
		&__body{
			margin-bottom: 0;
			display: table-cell;
			vertical-align:middle;
		}
		&__link-item {
			display: none;
		}
		&__status-mark-img {
			width: 2.5rem;
			margin-right: 1rem;
			margin-bottom: 0;
			vertical-align: middle;
/*
			display: inline-block;
			min-height: auto;
*/
		}
		&__status-text {
			display: inline-block;
			font-size: 1.3rem;
		}
		&__notes {
			font-size: 1.3rem;
			display: table-cell;
			vertical-align:middle;
			
			&-open, &-close, &-memo{
				font-size: 1.3rem;
			}
			& p {
				display: inline-block;
				padding-bottom: 0;
			}
		}
		&__date{
			display: inline;
			font-size: 1.3rem;
			font-weight: normal;
		}
		&__day-parts01{
			font-size: 1rem;
		}
		&__day-parts02{
			font-size: 1rem;
		}
		&__calendar {
			border: none;
			text-align: left;
			padding-top: 0;
			padding-bottom: 1.5rem;
			
			tbody {
			    border: 0.1rem solid #BFBFBF !important;
			}
			
		}
		&__calendar-head {
			display: none;
			&::after {
				content: "";
				height: 1rem;
				width: 100%;
				padding: 0.375rem 0;
			}
		}
		&__calendar-month {
			font-size: 2.4rem;
		}
		&__calendar-day{
			font-weight: bold;
			font-size: 1.8rem;
		}
		&__calendar-item {
			width: 100%;
			display: table;
			
			&--empty{
				display: none !important;
			}
			
		}
		&__calendar-inner {
			display: table-cell;
			height: 6rem;
			padding-right: 1.5rem;
			padding-left: 1.5rem;
			vertical-align: middle;
			@include clearfix
		}
		&__calendar-day {
			display: table-cell;
			vertical-align: middle;
			width: 10rem;
			font-size: 1.8rem;
		}
		&__calendar-data {
			display: table-cell;
			vertical-align: middle;
		}

		&__btn .btn-prev {
			width: 35%;
		}
		&__btn .btn-next {
			width: 35%;
		}

		&__holiday{
			width: 30%;
			margin-bottom: 0;
			img{
				padding-right: 0.4rem;
				padding-top: 0.4rem;
				padding-bottom: 0 !important;
			}
			
		}		
		
		&__navigation, &__btn{
			font-size: 1.4rem;
			align-items: center;
		}
		&__prev-btn{
			padding-left: 2rem;
			margin-bottom: 0;
			&:before{
				top: 0.35rem;
			   right: 3rem;
			   margin-top: 0rem;
			   background-size: 1.5rem 1.5rem;
			   width: 1.5rem;
			   height: 1.5rem;
		    }
		}
		&__next-btn{
			padding-right: 2rem;
			margin-bottom: 0;
			&:before{
				top: 0.35rem;
			   right: 0;
			   margin-top: 0rem;
			   background-size: 1.5rem 1.5rem;
			   width: 1.5rem;
			   height: 1.5rem;
		    }
		}
		&__today-item{
			font-size: 1.4rem;
			padding-bottom: 0;
		}
	}	
}