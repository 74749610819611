/* .p-tool__body
   -------------------------------------------------------------------------- */
/*doc
---
title: p-tool__body
name: p-tool__body
categories: [project]
---
グローバルナビのコンポーネントです。
*/

.p-tool {
  /* ボディのスタイル */
  &__body {
    display: flex;
    position: relative;
  }

  /* ボイスリンクのスタイル */
  &__voice a {
    display: inline-block;
    padding: 0.4rem 2.2rem;
    border: 0.1rem solid #333;
    border-radius: 0.4rem;
    margin-right: 2.4rem;
    text-decoration: none;
    font-size: 1.1rem;
  }

  /* フォントとカラーのスタイル */
  &__font,
  &__color {
    display: flex;
    align-items: center;

    &-ttl {
      font-size: 1.2rem;
      padding-right: 1.4rem;
      font-weight: normal;
    }

    &-body {
      display: flex;
      gap: 0.6rem;
      align-items: center;
    }
  }

  /* フォントの追加スタイル */
  &__font {
    padding-right: 2.4rem;
  }
}


#ss-voice-controller-0{
	position: absolute;
	left: -15rem;
	top: 4rem;
	z-index: 10;
}