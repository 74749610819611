 /* #Reject-container
   -------------------------------------------------------------------------- */
/*doc
---
title: reject-container
name: reject-container
categories: [layout]
---
bootstrapのcontainerの回避スタイルです。

```
*/
.l-reject-container {
     width:100vw; 
     position: relative;
     left: 50%;
     right: 50%;
     margin-left: -50vw;
     margin-right: -50vw;
}