 /* #Sidebar
   -------------------------------------------------------------------------- */
/*doc
---
title: sidebar
name: sidebar
categories: [layout]
---
`<aside>`タグが使われるようなサイドバーのスタイルです。
ナビゲーションやバナーなどのレイアウトを含むことはできますが、
コンポーネント自体はobject/projectレイヤーで定義します。
```block
<body>
	<article class="l-content"></article>
	<aside class="l-sidebar">
		<div class="l-sidebar__item">
			<nav class="p-local-nav"></nav>
		</div>
		<div class="l-sidebar__item">
			<div class="p-banner"></div>
		</div>
	</aside>
</body>
```
*/
.l-sidebar {
  padding-right: 2rem; // 幅の調整
  margin-bottom: 15rem;
}

@include mq-down('lg') {
  .l-sidebar {
    padding-right: 0;
    margin-bottom: 7rem;
  }
}
