/* #Font size
   -------------------------------------------------------------------------- */
/*doc
---
title: font-size
name: font-size
categories: [component]
---
文字サイズ変更のコンポーネントです。
*/

.c-font-size {
	@include clearfix;
	&--medium {
		font-size: 62.5% !important;
	}
	&--large {
		font-size: 72.5% !important;
	}
	&__btn {
		color: $_color-txt;
		background: #fff;
		border: 0.1rem solid #333;
		padding-top: 0.4rem;
		padding-bottom: 0.4rem;
		padding-left: 1rem;
		padding-right: 1rem;
		border-radius: $_radius;
		text-align: center;
		font-size: 1.2rem;
		
		&.is-active{
			background: #333;
			color: #fff;
		}
	}
}