/* .l-content -------------------------------------------------------------------------- */

.l-content {
  padding-top: 1.6rem;
  padding-bottom: 0rem;
  background-color: $_color05;
}

@include mq-up('lg') {
  .l-content {
    padding-top: 2.4rem;
    padding-bottom: 4rem;
  }
}


@media print{
	.l-content {
		background: #fff;
	}
}