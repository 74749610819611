/* #Google-map 
   -------------------------------------------------------------------------- */
/*doc
---
title: google-map
name: google-map
categories: [component]
---
Google map のコンポーネントです。
```block
<div class="c−google-map">
	<iframe></iframe>
</div>
```
*/
.c−google-map {
	position: relative;
	padding-bottom: 60%; //高さ調整
	padding-top: 30px;
	height: 0;
	overflow: hidden;
	iframe,object,embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: none;
	}
}