/* -----------------------------------------------------------------------------
   #Text
   -------------------------------------------------------------------------- */
/*doc
---
title: text
name: text
categories: [utility]
---
テキストのスタイルを指定する汎用クラスです。
```block
<p class="u-text-light">font-weight 300</p>
<p class="u-text-normal">font-weight 400</p>
<p class="u-text-bold">font-weight 600</p>
<p class="u-text-indent">先頭の1文字目を1文字分字下げします。</p>
<p class="u-text-note">2行目以降の1文字目を1文字分字下げします。<br>2行目以降の1文字目を1文字分字下げします。</p>
<p class="u-text-nowrap">テキストを折り返させません。横幅を超えても、このテキストは折り返さずに表示されます。</p>
<p class="u-text-breakword">テキストを折り返させます。長文になっても、このテキストは必ず折り返して表示されます。</p>
<p class="u-text-justify">テキストを両端揃え（均等割り付け）させます。通常のテキストと文字間が違うのが分かると思います。</p>
<p class="u-text-none">記述した通りに表示します。</p>
<p class="u-text-lowercase">abcdEFGH すべての英単語を小文字で表示します。</p>
<p class="u-text-uppercase">abcdEFGH すべての英単語を大文字で表示します。</p>
<p class="u-text-capitalize">abcdEFGH 英単語の先頭を大文字で表示します。</p>
<p class="u-text-smallcaps">abcdEFGH スモールキャップ（小文字と同じ高さで作られた大文字）で表示します。</p>
<p class="u-text-vertical">abcdEFGH 縦書きで表示します。</p>
<p class="u-text-vertical u-text-vertical--upright">abcdEFGH 英文も縦書きにする場合はModifierを指定します。</p>
<p class="u-text-right">text-align right</p>
<p class="u-text-center">text-align center</p>
<p class="u-text-left">text-align left</p>
<p class="u-text-top">vertical-align top</p>
<p class="u-text-middle">vertical-align middle</p>
<p class="u-text-bottom">vertical-align bottom</p>
```
*/

/* font-weightを指定します。 */
/*.u-text-light {
  font-weight: $_font-weight-light !important;
}

.u-text-normal {
  font-weight: $_font-weight-normal !important;
}

.u-text-bold {
  font-weight: $_font-weight-bold !important;
}*/

/* 先頭の1文字目を1文字分字下げします。 */
.u-text-indent {
  text-indent: 1em !important;
}

/* 2行目以降の1文字目を1文字分字下げします。 */
.u-text-note {
  margin-left: 1em !important;
  text-indent: -1em !important;
}

/* テキストを改行させません。 */
.u-text-nowrap {
  white-space: nowrap !important;
}

/* テキストを改行させます。 */
.u-text-breakword {
  word-wrap: break-word !important;
  overflow-wrap: break-word !important;
}

/* テキストを両端揃えさせます。 */
.u-text-justify {
  text-align: justify !important;
  text-justify: inter-ideograph !important;
}

/* 記述した通りに表示します。 */
.u-text-none {
  text-transform: none !important;
}

/* すべての英単語を小文字にします。 */
.u-text-lowercase {
  text-transform: lowercase !important;
}

/* すべての英単語を大文字にします。 */
.u-text-uppercase {
  text-transform: uppercase !important;
}

/* 英単語の先頭を大文字にします。 */
.u-text-capitalize {
  text-transform: capitalize !important;
}

/* スモールキャップ（小文字と同じ高さで作られた大文字）にします。 */
.u-text-smallcaps {
  font-variant: small-caps !important;
}

/* 縦書きにします。 */
.u-text-vertical {
  writing-mode: vertical-rl !important;
  text-orientation: mixed !important;
}

/* 英文も縦書きにする場合はModifierを指定します。 */
.u-text-vertical--upright {
  text-orientation: upright !important;
}

.u-text-right {
  text-align: right !important;
}

.u-text-center {
  text-align: center !important;
}

.u-text-left {
  text-align: left !important;
}

.u-text-top {
  vertical-align: top !important;
}

.u-text-middle {
  vertical-align: middle !important;
}

.u-text-bottom {
  vertical-align: bottom !important;
}