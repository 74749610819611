 /* #signage
   -------------------------------------------------------------------------- */
/*doc
---
title: signage
name: signage
categories: [layout]
---
*/

	
.l-signage {
	
	&__list{
		border-top: 1.3rem solid #ABCB82;
		background-color: #FBF4E4;
		min-height: 1080px;
		overflow: hidden;
		padding-left: 6rem;
		padding-right: 6rem;
		height: 100vh;
	}
	
	&__article{
		background-color: #fbf4e4;
		background-image: linear-gradient(90deg, #abcb8266 25px, transparent 25px), linear-gradient(#abcb8266 25px, transparent 25px);
		background-position: -5px -5px;
		background-size: 46px 46px;
		min-height: 1080px;
		overflow: hidden;
		height: 100vh;
		padding: 6rem;
	}
	
	
	
}

@media screen and (max-width: 991px) {
	
	
}
