.p-icon-menu {
  background-color: $_color03;
  padding-bottom: 4rem;
  padding-top: 4rem;

  &__list {
    grid-template-columns: repeat(5, 1fr);
    gap: 4rem;
    display: grid;
    place-content: center;
    place-items: center;
  }

  &__link {
    background-color: #fff;
    display: inline-block;
    padding: 1.6rem 0;
    text-decoration: none;
    text-align: center;
    border-radius: 0.8rem;
    @include txt-deco-none;
    box-shadow: 0px 3px 6px 0px #00000029;
    aspect-ratio: 1;
    max-width: 19rem;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    position: relative;

    &:visited {
      color: #333;
    }
  }

  &__img {
    width: 80%;
    display: inline-block;
  }

  &__txt {
    display: block;
    font-size: 2.4rem;
    width: 100%;
  }

  @include mq-down('xl') {
    &__list {
      gap: 3.2rem;
    }

    &__txt {
      font-size: 2.2rem;
    }
  }

  @include mq-down('lg') {
	  @media print{
	    padding-bottom: 2.4rem;
	    padding-top: 2.4rem;
	
	    &__list {
	      grid-template-columns: repeat(4, 1fr);
	      gap: 2.4rem;
	    }
	
	    &__link {
	      width: 16rem;
	    }
	
	    &__txt {
	      font-size: 2rem;
	    }
	  }
	  
	  @media print{
	    &__list {
	      gap: 2rem;
	    }
	    &__txt {
	      font-size: 1.8rem;
	    }		  
		  
	  }
	  
  }

  @include mq-down('lg') {
    padding-bottom: 2.4rem;
    padding-top: 1.4rem;

    &__list {
      grid-template-columns: repeat(3, 1fr);
      gap: 1.4rem;
    }

    &__link {
      width: calc(33.3% - 4.8rem);
      min-width: 10rem;
      padding: 0.8rem 0;
    }

    &__txt {
      font-size: 1.4rem;
    }
  }

  @include mq-down('msm') {
    &__list {
      grid-template-columns: repeat(3, 1fr);
      gap: 1rem;
    }

    &__link {
      min-width: 8.8rem;
      font-size: 1.2rem;
    }
  }
}