/* #Contactform
   -------------------------------------------------------------------------- */
/*doc
---
title: contactform
name: contactform
categories: [component]
---
contactformのコンポーネントです。
```block
<p class="c-paragraph"></p>
```
*/
#contactform {
	.contact-form {
	    background-color: #eeeeee;
	    /* border: 1px solid gray; */
	    padding: 1.5em;
	    border-radius: 5px;
	    .contact-form-field {
		    border-bottom: 1px dashed #888888;
		    padding-bottom: 0.5em;
		    margin-bottom: 0.5em;
	    }
	}
	.form-label {
	    /* float: left; */
	    margin-right: 1em;
	    min-width: 15em;
	    .must {
		    background-color: #CC0000;
		    padding: 0.25em;
		    font-size: 0.75em;
		    color: white;
		    font-weight: bold;
		    border-radius: 3px;
		    /* line-height: 0em; */
	    }
	}
	.form-element {
		.contact-form-text-full, .contact-form-textarea {
			width: 100%;
		}
	}
	.contact-form-submit {
		text-align: center;
		input[type=submit] {
			width: 6em;
		}
	}
	select {
		height: 2em;
	}
}
