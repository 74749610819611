/* sp-header -------------------------------------------------------------------------- */
.p-sp-header {
	& .container{
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-top: 1rem;
		padding-bottom: 1rem;
		background-color: #fff;
	}
	
	&__logo-link{
		display: flex;
		align-items: center;
		
		& img{
			max-width: 19.8rem;
		}
		
	}
	
}



@include mq-up('lg') {
	
	.p-sp-header {
		display: none;
	}
	
}