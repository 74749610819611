/* -----------------------------------------------------------------------------
   #Float
   -------------------------------------------------------------------------- */
/*doc
---
title: float
name: float
categories: [utility]
---

```block
```
*/

.u-float-left {
	float: left !important;
}

.u-float-right {
	float: right !important;
}

.u-clearfix:after {
	content:" ";
    display:block;
    clear:both;
}