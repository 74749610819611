/* #.p-tab
   -------------------------------------------------------------------------- */
/* タブコンポーネントのスタイル */

/*
#tabContent2 .p-tab01__item-parts{
	justify-content: space-between;
}
*/


.p-tab01 {
  /* タブリストのスタイル */
  &__tab-list {
    display: flex;
  }

  /* タブアイテムのスタイル */
  &__tab-item {
    width: 50%;
  }

  /* タブリンクのスタイル */
  &__tab-link {
    width: 100%;
    display: block;
    text-align: center;
    padding: 1rem 0;
    font-size: 1.6rem;
    text-decoration: none;
    background: $_color04;
    color: #fff;
    border-top: 0.4rem solid $_color04;

    &:visited {
      color: #fff;
    }

    &.is-select {
      background: #fff;
      color: #333;
    }
  }

  /* コンテンツリストのスタイル */
  &__cont {
    &-list {
      padding: 0.8rem 1rem 2.4rem;
      background: #fff;
    }

    &-item {
      &.is-hide {
        display: none;
      }
    }
  }

  /* アイテムのスタイル */
  &__item {
    padding: 1.6rem 0;
    border-bottom: 0.1rem solid #BFBFBF;

    &-parts {
      display: flex;
      margin-bottom: 0.8rem;
      //justify-content: space-between;
    }

    &-date {
      display: inline-block;
      align-content: center;
      padding-right: 0.8rem;
    }

    &-ttl {
      font-size: 1.4rem;
      font-weight: normal;
    }

    &__catarea {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 0.8rem;
      grid-row-gap: 0.4rem;
      width: min-content;
    }

    &-cat {
      padding: 0.2rem 0;
      border-radius: 3.8rem;
      width: 6rem;
      display: inline-block;
      text-align: center;
      font-size: 1.2rem;
      font-weight: bold;
      background: $_color07;
      border: 0.2rem solid $_color07;
      text-shadow: rgb(255, 255, 255) 2px 0px 0px, rgb(255, 255, 255) 1.75517px 0.958851px 0px, rgb(255, 255, 255) 1.0806px 1.68294px 0px, rgb(255, 255, 255) 0.141474px 1.99499px 0px, rgb(255, 255, 255) -0.832294px 1.81859px 0px, rgb(255, 255, 255) -1.60229px 1.19694px 0px, rgb(255, 255, 255) -1.97999px 0.28224px 0px, rgb(255, 255, 255) -1.87291px -0.701566px 0px, rgb(255, 255, 255) -1.30729px -1.51361px 0px, rgb(255, 255, 255) -0.421592px -1.95506px 0px, rgb(255, 255, 255) 0.567324px -1.91785px 0px, rgb(255, 255, 255) 1.41734px -1.41108px 0px, rgb(255, 255, 255) 1.92034px -0.558831px 0px;

      &--genre {
        background: #fff;
        width: 11rem;
      }

      &--blue {
        background: #a9cce6;
        border: #a9cce6;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  /* フッターのスタイル */
  &__foot {
    padding-top: 2.4rem;
  }
}

/* 大きな画面向けのスタイル */
@include mq-up('lg') {
  .p-tab01 {
    &__tab-link {
      font-size: 2.4rem;
      padding: 1.4rem 0;
    }

    &__cont {
      &-list {
        padding: 0.8rem 6.4rem 2.4rem;
      }
    }

    &__item {
      &-date {
        width: fit-content;
        padding-right: 1.2rem;
      }

      &-cat {
        padding: 0.3rem 0;
        width: 13rem;
        font-size: 1.4rem;
        height: fit-content;
        text-shadow: rgb(255, 255, 255) 3px 0px 0px, rgb(255, 255, 255) 2.83487px 0.981584px 0px, rgb(255, 255, 255) 2.35766px 1.85511px 0px, rgb(255, 255, 255) 1.62091px 2.52441px 0px, rgb(255, 255, 255) 0.705713px 2.91581px 0px, rgb(255, 255, 255) -0.287171px 2.98622px 0px, rgb(255, 255, 255) -1.24844px 2.72789px 0px, rgb(255, 255, 255) -2.07227px 2.16926px 0px, rgb(255, 255, 255) -2.66798px 1.37182px 0px, rgb(255, 255, 255) -2.96998px 0.42336px 0px, rgb(255, 255, 255) -2.94502px -0.571704px 0px, rgb(255, 255, 255) -2.59586px -1.50383px 0px, rgb(255, 255, 255) -1.96093px -2.27041px 0px, rgb(255, 255, 255) -1.11013px -2.78704px 0px, rgb(255, 255, 255) -0.137119px -2.99686px 0px, rgb(255, 255, 255) 0.850987px -2.87677px 0px, rgb(255, 255, 255) 1.74541px -2.43999px 0px, rgb(255, 255, 255) 2.44769px -1.73459px 0px, rgb(255, 255, 255) 2.88051px -0.838247px 0px;

        &:last-of-type {
          margin-right: 0;
        }
      }

      &-ttl {
        font-size: 1.8rem;
      }
    }

    &__foot {
      .p-btn01 {
        font-size: 1.8rem;
        padding: 1.6rem;
      }
    }
  }
}