.p-library {
  /*施設案内インデックスここから*/
  &-index {
    &__ttl {
      margin-bottom: 1rem;
      margin-top: 2.4rem;
      padding: 0.6rem 1rem;
      font-size: 1.8rem;
      position: relative;
      font-weight: normal;

      &::before {
        position: absolute;
        content: "";
        left: 0;
        bottom: 13%;
        width: 0.4rem;
        height: 70%;
        border-radius: 1.6rem;
        background: $_color04;
      }
    }
  }
  /*施設案内インデックスここまで*/

  &__events {
    padding-bottom: 3.2rem;
	
    .p-info__list {
      padding-bottom: 2.4rem;
    }
    
    .p-info__cat.p-info__cat--genre{
	    margin-left: 0.8rem;
    }
    
    .p-info__date {
            min-width: 10.6rem;
            display: block;
    }
    
    .p-info__parts.wrap .p-info__cat.p-info__cat--genre {
        width: 9rem;
    }
	.p-info__parts.wrap {
        flex-wrap: wrap;
    }
	.p-info__parts.wrap .p-info__date {
        width: 100%;
    }
	.p-info__parts.wrap p-info__cat--genre {
        margin-bottom: 0.4rem;
    }
    
  }

  &__schedule {
    background: #fff;
    padding: 1.6rem;
    margin-bottom: 2.4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 8.3rem;

    &-link {
      align-content: center;

      .p-btn01 {
        font-size: 1.4rem;
        padding: 0.5rem 2.4rem;
      }
    }

    &-status {
      padding-right: 1.2rem;
      padding-top: 0.2rem;

      &-mark {
        width: 3rem;
        height: 3rem;
        align-content: center;

        &--img {
          margin: auto;
          width: 3rem;
          height: auto;
        }
      }

      &-text {
        text-align: center;
      }
    }

    &-left {
      display: flex;
      align-items: center;
    }

    &-body {
      align-content: center;
    }
  }

  &__img {
    padding-bottom: 1.8rem;

    img {
      max-height: 28.4rem;
      width: 100%;
      display: block;
      object-fit: cover;
      object-position: 50% 100%;
    }
  }
  
  @include mq-up('lg') {
    /*施設案内インデックスここから*/
    &-index {
      display: grid;
      grid-template-columns: 1fr 1fr;
      row-gap: 3.2rem;
      column-gap: 10rem;

      &__item {
        margin-top: 4rem;
      }

      &__ttl {
        margin-top: 0;
        font-size: 2.4rem;

        &::before {
          bottom: 14%;
          width: 0.5rem;
          height: 70%;
        }
      }
    }
    /*施設案内インデックスここまで*/

    &__schedule {
      min-height: 10.6rem;

      &-link {
        .p-btn01 {
          font-size: 1.8rem;
          padding: 1.4rem 2rem;
        }
      }

      &-status {
        padding-right: 2rem;

        &-mark {
          padding-bottom: 0.4rem;
          width: 4rem;
          height: 4rem;
          align-content: center;

          &--img {
            width: 4rem;
          }
        }

        &-text {
          text-align: center;
          font-size: 2rem;
        }
      }

      &-notes {
        font-size: 2rem;
      }
    }

    &__events {
      .p-info-select {
        &-box {
          display: flex;
          max-width: 100%;

          &-txt {
            align-content: center;
            padding-right: 3.2rem;
          }

          &-form {
            width: 100%;
          }
        }
      }
    }

    &__img {
      padding: 0;
    }
  }

  @include mq-up('xl') {
    &__schedule-status {
      padding-right: 4.2rem;
    }

    &__schedule-link .p-btn01 {
      padding: 1.4rem 5rem;
    }
  }
}