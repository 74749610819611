/* #search-form
   -------------------------------------------------------------------------- */
/*doc
---
title: search-form
name: search-form
categories: [project]
---
*/


.p-404__search{
	
	& form.site-search-history-part{
		display: flex;
	}
	
	.site-search-keyword {
		display: inline-block;
		width: 100%;
		padding-right: 0.8rem;
		
		& .site-search-history{
			display: none !important;
		}
		
	}
	
	button#search-button {
		padding: 0.8rem;
	    display: block;
	    width: 8rem;
	    border: none;
	    border-radius: 0.4rem;
	    background: $_color04;
	    color: #fff;
	}
	
	
	input#s_keyword {
		width: 100%;
		padding: 0.8rem;
		border-radius: 0.4rem;
		border: 0.1rem solid;
	}

	
}


@media screen and (min-width: 992px) {
	
	.p-404__search{
		
		.site-search-keyword {
			display: inline-block;
			padding-right: 1.4rem;
		}
		
		button#search-button {
		    width: 10rem;
		    padding: 1.4rem;
		}
		
		input#s_keyword{
			padding: 1.4rem;
		}
	
		
	}	
	
}