.faq-search {
  & .category,
  & .keyword {
    padding-bottom: 1.4rem;
  }

  #category {
    width: 100%;
    padding: 1rem 0.8rem;
    border: 0.1rem solid #333;
    border-radius: 0.4rem;
    outline: 0;
    background: #fff;
    appearance: none;
    margin-top: 0.4rem;
  }

  & .category {
    position: relative;

    &::after {
      display: inline-block;
      position: absolute;
      bottom: 0;
      right: 1.6rem;
      width: 0.6rem;
      height: 1.1rem;
      margin-bottom: 2.8rem;
      background: url(../images/ico_arrow03.svg);
      background-repeat: no-repeat;
      background-size: contain;
      content: "";
      transform: rotate(90deg);

      @include mq-up('lg') {
        margin-bottom: 1.6rem;
      }
    }
  }

  & .category label,
  & .keyword label {
    display: inline-block;
    font-size: 1.6rem;

    @include mq-up('lg') {
      font-size: 2rem;
    }
  }

  & #category_ids_,
  & #keyword {
    width: 100%;
    padding: 1rem 0.8rem;
    border: 0.1rem solid #333;
    border-radius: 0.4rem;
    outline: 0;
    background: #fff;
    appearance: none;
    display: inline-block;
    margin-top: 0.4rem;
  }

  & .submitters {
    display: flex;
    justify-content: center;

    input[name="search"],
    input[name="reset"] {
      display: inline-block;
      padding: 1rem 1.6rem;
      border: none;
      border-radius: 0.4rem;
      background-color: $_color04;
      color: #fff;
      font-size: 1.4rem;
      margin-right: 2.4rem;
      margin-left: 2.4rem;
      min-width: 8.8rem;
      border: 0.15rem solid $_color04;
      max-height: 4.8rem;

      &.reset {
        background-color: #fff;
        color: $_color04;
      }
    }
  }

  @include mq-up('lg') {
    form {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-column-gap: 2.4rem;
      grid-row-gap: 2.4rem;
    }

    & .category,
    & .keyword {
      padding-bottom: 0;
    }

    .category {
      grid-area: 1 / 1 / 2 / 2;
    }

    .keyword {
      grid-area: 1 / 2 / 2 / 3;
    }

    .submitters {
      grid-area: 2 / 1 / 3 / 3;
    }
  }
}

.faq-question p {
  display: none;
}

.faq-search.pages {
  padding-bottom: 4.8rem;
}

h2.result {
  text-align: center;
  font-weight: normal;
  font-size: 1.8rem;
  padding: 0.8rem;
  margin-top: 1.8rem;
  border-bottom: 0.2rem dashed $_color03;
  margin-bottom: 2.4rem;

  & span {
    padding-left: 0.4rem;
    padding-right: 0.4rem;
    font-size: 2.4rem;
  }

  @include mq-up('lg') {
    font-size: 2.4rem;
    margin-top: 3.2rem;
    margin-bottom: 4rem;

    & span {
      padding-left: 0.8rem;
      padding-right: 0.8rem;
      font-size: 3.2rem;
    }
  }
}

.p-faq-search {
  &__item {
    padding-bottom: 1.4rem;
  }

  &__link {
    position: relative;
    padding: 1.2rem 0.6rem 1.2rem 3.2rem;
    background: #fff;
    text-decoration: none;
    width: 100%;
    display: block;
    border: 0.2rem solid #fff;

    &::before {
      display: inline-block;
      position: absolute;
      top: 1.3rem;
      left: 0.6rem;
      width: 2rem;
      height: 2rem;
      background: url(../images/ico_faq-q.svg);
      background-size: contain;
      background-repeat: no-repeat;
      content: "";
    }

    &::after {
      position: absolute;
      content: "";
      bottom: 0;
      right: 0;
      border-top: 1.42rem solid transparent;
      border-right: 1.42rem solid $_color03;
    }

    @include mq-up('lg') {
      border: 0.4rem solid #fff;

      &::before {
        top: 1.5rem;
      }
    }
  }
}