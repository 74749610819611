/* p-topics-important -------------------------------------------------------------------------- */
/* 重要なお知らせのスタイル */
.p-topics-important {
  background: #fff;
  padding: 1.6rem 0.8rem;
  border-radius: $_radius;

  /* タイトルのスタイル */
  &__ttl {
    text-align: center;
    font-size: 1.8rem;
    padding-left: 3.5rem;
    padding-right: 3.5rem;
    position: relative;
    display: inline-block;
    transform: translateX(-50%);
    left: 50%;

    &::before,
    &::after {
      position: absolute;
      content: "";
      top: 50%;
      width: 2.704rem;
      height: 2.6rem;
      margin-top: -1.3rem;
      background: url(/common/images/ico_important01.svg) no-repeat;
      background-size: contain;
    }

    &::before {
      left: 0;
    }

    &::after {
      right: 0;
    }
  }

  /* アイテムのスタイル */
  &__item {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
    border-bottom: 0.1rem dashed #333;

    &:last-of-type {
      border-bottom: 0;
      padding-bottom: 0;
    }
  }
}

/* 大きな画面向けのスタイル */
@include mq-up('lg') {
  .p-topics-important {
    padding: 1.8rem 4rem;

    &__ttl {
      font-size: 2.8rem;
      padding-left: 4.3rem;
      padding-right: 4.3rem;

      &::before,
      &::after {
        width: 3.5rem;
        height: 3.128rem;
        margin-top: -1.75rem;
      }
    }

    &__item {
      padding-top: 1.8rem;
      padding-bottom: 2.4rem;
    }
  }
}