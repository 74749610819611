/* #u-row-width
   -------------------------------------------------------------------------- */
/*doc
---
title: u-row-width
name: u-row-width
categories: [utility]
---
Bootstrapのガター幅を調整します。
```block
<div class="row u-row-0 u-row-md-10 u-row-sm-20 u-row-xs-30">
    <div class="col-sm-6">
    </div>
    <div class="col-sm-6">
    </div>
</div>
```
*/
 /* ガター幅（余白）指定
--------------------------------------------- */
/* lg以下 */
.u-row-none{
    margin-left:0px !important;
    margin-right:0px !important;
    > div,li {
        padding-right:0px !important;
        padding-left:0px !important;
    }
}
.u-row-small{
    margin-left:-5px !important;
    margin-right:-5px !important;
    > div,li {
        padding-right:5px !important;
        padding-left:5px !important;
    }
}
.u-row-medium{
    margin-left:-10px !important;
    margin-right:-10px !important;
    > div,li {
        padding-right:10px !important;
        padding-left:10px !important;
    }
}
.u-row-large{
    margin-left:-15px !important;
    margin-right:-15px !important;
    > div,li {
        padding-right:15px !important;
        padding-left:15px !important;
    }
}
/* md以下 */
@media screen and (max-width: 1199px) {
    .u-row-md-none{
        margin-left:0px !important;
        margin-right:0px !important;
        > div,li {
            padding-right:0px !important;
            padding-left:0px !important;
        }
    }
    .u-row-md-small{
        margin-left:-5px !important;
        margin-right:-5px !important;
        > div,li {
            padding-right:5px !important;
            padding-left:5px !important;
        }
    }
    .u-row-md-medium{
        margin-left:-10px !important;
        margin-right:-10px !important;
        > div,li {
            padding-right:10px !important;
            padding-left:10px !important;
        }
    }
    .u-row-md-large{
        margin-left:-15px !important;
        margin-right:-15px !important;
        > div,li {
            padding-right:15px !important;
            padding-left:15px !important;
        }
    }
}
/* sm以下 */
@media screen and (max-width: 991px) {
    .u-row-sm-none{
        margin-left:0px !important;
        margin-right:0px !important;
        > div,li {
            padding-right:0px !important;
            padding-left:0px !important;
        }
    }
    .u-row-sm-small{
        margin-left:-5px !important;
        margin-right:-5px !important;
        > div,li {
            padding-right:5px !important;
            padding-left:5px !important;
        }
    }
    .u-row-sm-medium{
        margin-left:-10px !important;
        margin-right:-10px !important;
        > div,li {
            padding-right:10px !important;
            padding-left:10px !important;
        }
    }
    .u-row-sm-large{
        margin-left:-15px !important;
        margin-right:-15px !important;
        > div,li {
            padding-right:15px !important;
            padding-left:15px !important;
        }
    }
}
/* xs以下 */
@media screen and (max-width: 767px) {
    .u-row-xs-none{
        margin-left:0px !important;
        margin-right:0px !important;
        > div,li {
            padding-right:0px !important;
            padding-left:0px !important;
        }
    }
    .u-row-xs-small{
        margin-left:-5px !important;
        margin-right:-5px !important;
        > div,li {
            padding-right:5px !important;
            padding-left:5px !important;
        }
    }
    .u-row-xs-medium{
        margin-left:-10px !important;
        margin-right:-10px !important;
        > div,li {
            padding-right:10px !important;
            padding-left:10px !important;
        }
    }
    .u-row-xs-large{
        margin-left:-15px !important;
        margin-right:-15px !important;
        > div,li {
            padding-right:15px !important;
            padding-left:15px !important;
        }
    }
}